import React, {useEffect, useState} from 'react';
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardTitle,
    FormGroup,
    Label,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane, Spinner,
} from 'reactstrap';
import {useTranslation} from 'react-i18next';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import * as yup from "yup";
import {Form, Formik, setFieldValue} from "formik";
import FormTextField from "../../common/formComponents/FormTextField";
import FormDropdown from "../../common/formComponents/FormDropdown";
import { getBankCurrency, getCountries} from "../../services/CommonService";
import {toast} from "react-toastify";
import {PayeeRegister} from "../../services/PayeeService";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";


const RegisterPayee = (props) => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)

    const [registerPayeeState, setRegisterPayeeState] = useState({
        registerPayeeActiveTab: '1',
    });

    const user = useSelector(state => state.auth.data.userDTO)
    const isAccountActive = user?.isActive

    const toggleCustom = (tab) => {
        if (registerPayeeState.registerPayeeActiveTab !== tab) {
            setRegisterPayeeState({
                registerPayeeActiveTab: tab,
            });
        }
    };

    const {t} = useTranslation()

    const [formFieldConfigsData, setFormFieldConfigsData] = useState([
        {
            country: "Singapore",
            code: "SG",
            currency: "SGD",
            payementMethod: "Local",
            localClearingSystem: "FAST",
            accountRoutingType1: null,
            accountRoutingValue1: null,
            swiftCode: "DBSSSGSG"
        },
        {
            country: "Singapore",
            code: "SG",
            currency: "SGD",
            payementMethod: "Local",
            localClearingSystem: "GIRO",
            accountRoutingType1: null,
            accountRoutingValue1: null,
            swiftCode: "DBSSSGSG"
        },
        {
            country: "Singapore",
            code: "SG",
            currency: "SGD",
            payementMethod: "Local",
            localClearingSystem: "RTGS",
            accountRoutingType1: null,
            accountRoutingValue1: null,
            swiftCode: "DBSSSGSG"
        },




        {
            country: "Hong Kong",
            code: "HK",
            currency: "HKD",
            payementMethod: "Local",
            localClearingSystem: "ACH",
            accountRoutingType1: "bank_code",
            accountRoutingValue1: "024"
        },

        {
            country: "Hong Kong",
            code: "HK",
            currenc: "HKD",
            payementMethod: "Local",
            localClearingSystem: "FPS",
            accountRoutingType1: "bank_code",
            accountRoutingValue1: "024"
        },
        {
            country: "Hong Kong",
            code: "HK",
            currency: "HKD",
            payementMethod: "Local",
            localClearingSystem: "RTGS",
            accountRoutingType1: null,
            accountRoutingValue1: null,
            swiftCode: "DBSSSGSG"
        },



        {
            country: "China",
            code: "CN",
            currency: "CNY",
            payementMethod: "Local",
            localClearingSystem: null,
            accountRoutingType1: "cnaps",
            accountRoutingValue1: "105584000005"
        },



        {
            country: "United Kingdom",
            code: "GB",
            currency: "GBP",
            payementMethod: "Local",
            localClearingSystem: "FASTER_PAYMENTS",
            accountRoutingType1: "sort_code",
            accountRoutingValue1: "609242"
        },





        {
            country: "United Arab Emirates",
            code: "AE",
            currency: "AED",
            payementMethod: "Local",
            localClearingSystem: null,
            accountRoutingType1: null,
            accountRoutingValue1: null,
            iban: "AE070331234567890123456"
        },



        {
            country: "United State",
            code: "US",
            currency: "USD",
            payementMethod: "Local",
            localClearingSystem: "ACH",
            accountRoutingType1: "aba",
            accountRoutingValue1: "021000021"
        },
        {
            country: "United State",
            code: "US",
            currency: "USD",
            payementMethod: "Local",
            localClearingSystem: "FEDWIRE",
            accountRoutingType1: "aba",
            accountRoutingValue1: "021000021"
        },
        {
            country: "United State",
            code: "US",
            currency: "USD",
            payementMethod: "Local",
            localClearingSystem: "PAPER_CHECK",
            accountRoutingType1: null,
            accountRoutingValue1: null
        },



        {
            country: "Australia",
            code: "AU",
            currency: "AUD",
            payementMethod: "Local",
            localClearingSystem: "BANK_TRANSFER",
            accountRoutingType1: "bsb",
            accountRoutingValue1: "082401"
        },
        {
            country: "Australia",
            code: "AU",
            currency: "AUD",
            payementMethod: "Local",
            localClearingSystem: "BPAY",
            accountRoutingType1: "bpay_biller_code",
            accountRoutingValue1: "123000"
        },



        {
            country: "Japan",
            code: "JP",
            currency: "JPY",
            payementMethod: "Local",
            localClearingSystem: "",
            accountRoutingType1: "bank_code",
            accountRoutingValue1: "0001",
            accountRoutingType2: "branch_code",
            accountRoutingValue2: "001"
        },



        {
            country: "Canada",
            code: "CA",
            currency: "CAD",
            payementMethod: "Local",
            localClearingSystem: "",
            accountRoutingType1: "institution_number",
            accountRoutingValue1: "010",
            accountRoutingType2: "transit_number",
            accountRoutingValue2: "04832"
        },

        {
            country: "India",
            code: "IN",
            currency: "INR",
            payementMethod: "Local",
            localClearingSystem: null,
            accountRoutingType1: "bank_code",
            accountRoutingValue1: "ICIC0001962"
        }
    ])


    // Register Individual Payee

    const validationSchema = yup.object().shape({
        bankCountry: yup.string().required(t("BANK_COUNTRY_IS_REQUIRED"))
            .notOneOf([null, t("SELECT_AN_OPTION")], t("PLEASE_SELECT_A_VALID_COUNTRY"))
        ,
        accountCurrency: yup.string().required(t("ACCOUNT_CURRENCY_IS_REQUIRED"))
            .notOneOf([null, t("SELECT_AN_OPTION")], t("PLEASE_SELECT_A_VALID_ACCOUNT_CURRENCY"))
        ,
        bankCodeType: yup.string().required(t("TRANSFER_METHOD_IS_REQUIRED"))
            .notOneOf([null, t("SELECT_AN_OPTION")], t("PLEASE_SELECT_A_VALID_TRANSFER_METHOD"))
        ,
        swiftCode: yup.string().when("bankCodeType", {
            is: (bankCodeType) => bankCodeType === "swift",
            then: yup.string().required(t("SWIFT_CODE_IS_REQUIRED"))
        }),
        localSwiftCode: yup.string().when("bankCodeType", {
            is: (bankCodeType) => bankCodeType === "local - fast" || bankCodeType === "local - giro" || bankCodeType === "local - rtgs",
            then: yup.string().required(t("SWIFT_CODE_IS_REQUIRED"))
        }),
        accountRoutingValue: yup.string().when(["bankCodeType", "bankCountry"], {
            is: (bankCodeType, bankCountry) => bankCodeType === "local - ach" ||
                bankCodeType === "local - fps" ||
                (bankCodeType === "local" && bankCountry === "CN") ||
            bankCodeType === "local - faster_payments" ||
            // bankCodeType === "local - paper_check" ||
            bankCodeType === "local - fedwire" ||
            bankCodeType === "local - bpay" ||
            bankCodeType === "local - bank_transfer" ||
                (bankCodeType === "local" && bankCountry === "JP") ||
                (bankCodeType === "local" && bankCountry === "CA") ||
                (bankCodeType === "local" && bankCountry === "IN")
            ,
            then: yup.string().required(t("ACCOUNT_ROUTING_VALUE_IS_REQUIRED"))
        }),
        bankAccountNumber: yup.string()
            .required(t("BANK_ACCOUNT_NUMBER_IS_REQUIRED"))
            .min(8, t("BANK_ACCOUNT_NUMBER_MIN_LENGTH"))
            .matches(/^\d+$/, t("BANK_ACCOUNT_NUMBER_MUST_BE_NUMERIC")),
        bankAccountName: yup.string().required(t("BANK_ACCOUNT_NAME_IS_REQUIRED")),
         bankName: yup.string().required(t("BANK_NAME_IS_REQUIRED")),
    })

    const validationSchemaIn2 = yup.object().shape({
        firstName: yup.string().required(t("FIRST_NAME_IS_REQUIRED")),
        lastName: yup.string().required(t("LAST_NAME_IS_REQUIRED")),
        nickName: yup.string().required(t("NICK_NAME_IS_REQUIRED")),
        email: yup.string().email(t("EMAIL_MUST_BE_VALID")).required(t("EMAIL_IS_REQUIRED")),
        address: yup.string().required(t("ADDRESS_IS_REQUIRED")),
        postalCode: yup.string().required(t("POSTAL_CODE_IS_REQUIRED")),
        city: yup.string().required(t("CITY_IS_REQUIRED")),
        state: yup.string().required(t("STATE_IS_REQUIRED")),
    })

    const [isBankCurrency, setBankCurrency] = useState([])

    const [countries, setCountries] = useState([]);

    const [isBankCodeType, setBankCodeType] = useState([])

    const [transferMethods, setTransferMethods] = useState([]);


    useEffect(() => {
        if(isAccountActive){
            fetchCountries()
            fetchBankCurrency()
        }
    }, [isAccountActive])

    const fetchCountries = async () => {
        try {
            const response = await getCountries();
            const data = response.result; // Assuming your response contains the data array

            // Transform data for setCountries
            const countriesData = data.map((country) => ({
                ...country,
                label: country.country_name,
                value: country.country_code, // Assuming you want the value in lowercase
            }));
            setCountries(countriesData);
        } catch (error) {
            toast.error(t("ERROR_FETCH_COUNTRIES"));
        }
    };

    const fetchBankCurrency = async () => {
        try {
            const response = await getBankCurrency()

            let bankCurrency = response.result.map(bankCurrency => ({
                label: bankCurrency.bank_currency,
                value: bankCurrency.bank_currency_code,
            }));

            setBankCurrency(bankCurrency)

        } catch (error) {
            toast.error(t("ERROR_FETCH_BANK_CURRENCY"))
        }
    }

    const [isSubmitRegisterIndividualPayeeBankAccountDetails, setSubmitRegisterIndividualPayeeBankAccountDetails] = useState([])
    const [isSubmitRegisterBusinessPayeeBankAccountDetails, setSubmitRegisterBusinessPayeeBankAccountDetails] = useState([])

    const onSubmitRegisterIndividualPayeeBankAccountDetails = (values)=>{
        setSubmitRegisterIndividualPayeeBankAccountDetails(values)
        setactiveTabIn(2)
    }

    const onSubmitRegisterIndividualPayee = async (values, { resetForm }) => {

        setLoading(true)

        let localClearVal = ""
        let localClearVal1 = ""

        if (isSubmitRegisterIndividualPayeeBankAccountDetails && isSubmitRegisterIndividualPayeeBankAccountDetails.bankCodeType) {
            localClearVal = isSubmitRegisterIndividualPayeeBankAccountDetails.bankCodeType.split("-")[0].replace(/\s/g, '').toLowerCase().length > 0 ? isSubmitRegisterIndividualPayeeBankAccountDetails.bankCodeType.split("-")[0].replace(/\s/g, '').toLowerCase() : "";
        }

        if (isSubmitRegisterIndividualPayeeBankAccountDetails && isSubmitRegisterIndividualPayeeBankAccountDetails.bankCodeType) {
            localClearVal1 = isSubmitRegisterIndividualPayeeBankAccountDetails?.bankCodeType?.split("-")[1]?.replace(/\s/g, '').toLowerCase().length > 0 ? isSubmitRegisterIndividualPayeeBankAccountDetails?.bankCodeType?.split("-")[1]?.replace(/\s/g, '').toLowerCase() : "";
        }

         let configData = formFieldConfigsData.find(obj =>
            obj?.code?.toLowerCase() === isSubmitRegisterIndividualPayeeBankAccountDetails.bankCountry.toLowerCase() &&
            obj?.payementMethod?.toLowerCase() === localClearVal &&
            obj?.localClearingSystem?.toLowerCase() === localClearVal1

        );


        let data = {
            co_user_id: user.coUserId,
            payee_type: "PERSONAL",
            first_name: values.firstName,
            last_name: values.lastName,
            bank_code_type: isSubmitRegisterIndividualPayeeBankAccountDetails.bankCountry.toUpperCase(),
            bank_code: "",
            bank_name: isSubmitRegisterIndividualPayeeBankAccountDetails.bankName,
            branch_name: "",
            account_number: isSubmitRegisterIndividualPayeeBankAccountDetails.bankAccountNumber,
            account_name: isSubmitRegisterIndividualPayeeBankAccountDetails.bankAccountName,
            bank_country: isSubmitRegisterIndividualPayeeBankAccountDetails.bankCountry.toUpperCase(),
            bank_currency: isSubmitRegisterIndividualPayeeBankAccountDetails.accountCurrency.toUpperCase(),
            nick_name: values.nickName,
            email: values.email,
            id_issued_in: "",
            id_type: "",
            id_number: "",
            id_image: "",
            br_number: "",
            cnpas: "",
            is_active: true,
            is_verified: false,
            company_name: "",
            swift_code: isSubmitRegisterIndividualPayeeBankAccountDetails.swiftCode ? isSubmitRegisterIndividualPayeeBankAccountDetails.swiftCode : isSubmitRegisterIndividualPayeeBankAccountDetails.localSwiftCode ? isSubmitRegisterIndividualPayeeBankAccountDetails.localSwiftCode : null,
            account_routing_type1: configData?.accountRoutingType1 ? configData.accountRoutingType1 : null,
            account_routing_value1: isSubmitRegisterIndividualPayeeBankAccountDetails.accountRoutingValue ? isSubmitRegisterIndividualPayeeBankAccountDetails.accountRoutingValue : null,
            local_clearing_system: configData?.localClearingSystem ? configData.localClearingSystem.toUpperCase() : null,
            payment_methods: [
                isSubmitRegisterIndividualPayeeBankAccountDetails.bankCodeType.split("-")[0].replace(/\s/g, '').toUpperCase()
            ],
            addressList: [
                {
                    address_type: "PERSONAL",
                    address_line1: values.address,
                    address_line2: "",
                    address_line3: "",
                    city_province: values.city,
                    street: "my streat",
                    postal_code: values.postalCode,
                    state: values.state
                }
            ]
        }

        try {
            const response = await PayeeRegister(data)

            setLoading(false)
            toast.success(t("REGISTER_INDIVIDUAL_PAYEE_SUCCESS"))
             setactiveTabIn(1)
             resetForm()
             navigate('/payee')
        } catch (error) {
            setLoading(false)
            if (error.code === "ERR_NETWORK") {

                //toast.error(`Error: ${error.message}`)
                setLoading(false)
            }else if(error.response.data.errorType === "Airwallex side failure!"){

                let errorMessage = error.response.data.result.error.errors[0].source ? error.response.data.result.error.errors[0].source : error.response.data.result.error.errors[0].code;

                if (errorMessage && errorMessage.includes("beneficiary.address.")) {
                    errorMessage = errorMessage.replace("beneficiary.address.", "");
                }

                if (errorMessage && errorMessage.includes("beneficiary.bank_details.")) {
                    errorMessage = errorMessage.replace("beneficiary.bank_details.", "");
                }

                toast.error(`Error: ${errorMessage}`)
                setLoading(false)
            }
             else {
                 toast.error(`Error: ${error.response.data.result.error}`)
                setLoading(false)
             }
            //setLoading(false)
        }
    }

    // Register Business Payee
    const validationSchemaRegisterBusinessPayee = yup.object().shape({
        bankCountry: yup.string().required(t("BANK_COUNTRY_IS_REQUIRED"))
            .notOneOf([null, t("SELECT_AN_OPTION")], t("PLEASE_SELECT_A_VALID_COUNTRY"))
        ,
        accountCurrency: yup.string().required(t("ACCOUNT_CURRENCY_IS_REQUIRED"))
            .notOneOf([null, t("SELECT_AN_OPTION")], t("PLEASE_SELECT_A_VALID_ACCOUNT_CURRENCY"))
        ,
        bankCodeType: yup.string().required(t("TRANSFER_METHOD_IS_REQUIRED"))
            .notOneOf([null, t("SELECT_AN_OPTION")], t("PLEASE_SELECT_A_VALID_TRANSFER_METHOD"))
        ,
        swiftCode: yup.string().when("bankCodeType", {
            is: (bankCodeType) => bankCodeType === "swift",
            then: yup.string().required(t("SWIFT_CODE_IS_REQUIRED"))
        }),
        localSwiftCode: yup.string().when("bankCodeType", {
            is: (bankCodeType) => bankCodeType === "local - fast" || bankCodeType === "local - giro" || bankCodeType === "local - rtgs",
            then: yup.string().required(t("SWIFT_CODE_IS_REQUIRED"))
        }),
        accountRoutingValue: yup.string().when(["bankCodeType", "bankCountry"], {
            is: (bankCodeType, bankCountry) => bankCodeType === "local - ach" ||
                bankCodeType === "local - fps" ||
                (bankCodeType === "local" && bankCountry === "CN") ||
                bankCodeType === "local - faster_payments" ||
                // bankCodeType === "local - paper_check" ||
                bankCodeType === "local - fedwire" ||
                bankCodeType === "local - bpay" ||
                bankCodeType === "local - bank_transfer" ||
                (bankCodeType === "local" && bankCountry === "JP") ||
                (bankCodeType === "local" && bankCountry === "CA") ||
                (bankCodeType === "local" && bankCountry === "IN")
            ,
            then: yup.string().required(t("ACCOUNT_ROUTING_VALUE_IS_REQUIRED"))
        }),
        bankAccountNumber: yup.string()
            .required(t("BANK_ACCOUNT_NUMBER_IS_REQUIRED"))
            .min(8, t("BANK_ACCOUNT_NUMBER_MIN_LENGTH"))
            .matches(/^\d+$/, t("BANK_ACCOUNT_NUMBER_MUST_BE_NUMERIC")),
        bankAccountName: yup.string().required(t("BANK_ACCOUNT_NAME_IS_REQUIRED")),
        bankName: yup.string().required(t("BANK_NAME_IS_REQUIRED")),
    })

    const validationSchemaRegisterBusinessPayee2 = yup.object().shape({
        businessName: yup.string().required(t("BUSINESS_NAME_IS_REQUIRED")),
        nickName: yup.string().required(t("NICK_NAME_IS_REQUIRED")),
        email: yup.string().email(t("EMAIL_MUST_BE_VALID")).required(t("EMAIL_IS_REQUIRED")),
        address: yup.string().required(t("ADDRESS_IS_REQUIRED")),
        postalCode: yup.string().required(t("POSTAL_CODE_IS_REQUIRED")),
        city: yup.string().required(t("CITY_IS_REQUIRED")),
        state: yup.string().required(t("STATE_IS_REQUIRED")),

        legalRepFName: yup.string().when("bankCountry", {
            is: (bankCountry) => (isSubmitRegisterBusinessPayeeBankAccountDetails.bankCountry && isSubmitRegisterBusinessPayeeBankAccountDetails.bankCountry === "CN"),
            then: yup.string().required(t("LEGAL_REPRESENTATIVE_FIRST_NAME_IN_CHINESE_LANGUAGE"))
        }),
        legalRepLName: yup.string().when("bankCountry", {
            is: (bankCountry) => (isSubmitRegisterBusinessPayeeBankAccountDetails.bankCountry && isSubmitRegisterBusinessPayeeBankAccountDetails.bankCountry === "CN"),
            then: yup.string().required(t("LEGAL_REPRESENTATIVE_LAST_NAME_IN_CHINESE_LANGUAGE"))
        }),
    })


    const onSubmitRegisterBusinessPayeeBankAccountDetails = (values)=>{
        setSubmitRegisterBusinessPayeeBankAccountDetails(values)
        setactiveTab(2)
    }

    const onSubmitRegisterBusinessPayee = async (values, { resetForm }) => {

        setLoading(true)
        let localClearVal = ""
        let localClearVal1 = ""

        if (isSubmitRegisterBusinessPayeeBankAccountDetails && isSubmitRegisterBusinessPayeeBankAccountDetails.bankCodeType) {
            localClearVal = isSubmitRegisterBusinessPayeeBankAccountDetails.bankCodeType.split("-")[0].replace(/\s/g, '').toLowerCase().length > 0 ? isSubmitRegisterBusinessPayeeBankAccountDetails.bankCodeType.split("-")[0].replace(/\s/g, '').toLowerCase() : "";
        }

        if (isSubmitRegisterBusinessPayeeBankAccountDetails && isSubmitRegisterBusinessPayeeBankAccountDetails.bankCodeType) {
            localClearVal1 = isSubmitRegisterBusinessPayeeBankAccountDetails?.bankCodeType?.split("-")[1]?.replace(/\s/g, '').toLowerCase().length > 0 ? isSubmitRegisterBusinessPayeeBankAccountDetails?.bankCodeType?.split("-")[1]?.replace(/\s/g, '').toLowerCase() : "";
        }

        let configData = formFieldConfigsData.find(obj =>
            obj.code.toLowerCase() === isSubmitRegisterBusinessPayeeBankAccountDetails.bankCountry.toLowerCase() &&
            obj.payementMethod.toLowerCase() === localClearVal &&
            obj.localClearingSystem.toLowerCase() === localClearVal1

        );


        let dataRegisterBusiness = {
            co_user_id: user.coUserId,
            payee_type: "COMPANY",
            first_name: "",
            last_name: "",
            bank_code_type: isSubmitRegisterBusinessPayeeBankAccountDetails.bankCountry.toUpperCase(),
            bank_code: "",
            bank_name: isSubmitRegisterBusinessPayeeBankAccountDetails.bankName,
            branch_name: "",
            account_number: isSubmitRegisterBusinessPayeeBankAccountDetails.bankAccountNumber,
            account_name: isSubmitRegisterBusinessPayeeBankAccountDetails.bankAccountName,
            bank_country: isSubmitRegisterBusinessPayeeBankAccountDetails.bankCountry.toUpperCase(),
            bank_currency: isSubmitRegisterBusinessPayeeBankAccountDetails.accountCurrency.toUpperCase(),
            nick_name: values.nickName,
            email: values.email,
            id_issued_in: "",
            id_type: "",
            id_number: "",
            id_image: "",
            br_number: "",
            cnpas: "",
            is_active: true,
            is_verified: false,
            legal_rep_last_name: values.legalRepLName,
            legal_rep_first_name: values.legalRepFName,
            company_name: values.businessName,
            swift_code: isSubmitRegisterBusinessPayeeBankAccountDetails.swiftCode ? isSubmitRegisterBusinessPayeeBankAccountDetails.swiftCode : isSubmitRegisterBusinessPayeeBankAccountDetails.localSwiftCode ? isSubmitRegisterBusinessPayeeBankAccountDetails.localSwiftCode : null,
            account_routing_type1: configData?.accountRoutingType1 ? configData.accountRoutingType1 : null,
            account_routing_value1: isSubmitRegisterBusinessPayeeBankAccountDetails.accountRoutingValue ? isSubmitRegisterBusinessPayeeBankAccountDetails.accountRoutingValue : null,
            local_clearing_system: configData?.localClearingSystem ? configData.localClearingSystem.toUpperCase() : null,
            payment_methods: [
                isSubmitRegisterBusinessPayeeBankAccountDetails.bankCodeType.split("-")[0].replace(/\s/g, '').toUpperCase()
            ],
            addressList: [
                {
                    address_type: "COMPANY",
                    address_line1: values.address,
                    address_line2: "",
                    address_line3: "",
                    city_province: values.city,
                    street: "my streat",
                    postal_code: values.postalCode,
                    state: values.state
                }
            ]
        }

        try {
            const response = await PayeeRegister(dataRegisterBusiness)
            setLoading(false)
             toast.success(t("REGISTER_BUSINESS_PAYEE_SUCCESS"))
             setactiveTab(1)
             resetForm()
             navigate('/payee')
        } catch (error) {
            setLoading(false)
            if (error.code === "ERR_NETWORK") {
                setLoading(false)
            }else if(error.response.data.errorType === "Airwallex side failure!"){

                let errorMessage = error.response.data.result.error.errors[0].source ? error.response.data.result.error.errors[0].source : error.response.data.result.error.errors[0].code;

                if (errorMessage && errorMessage.includes("beneficiary.address.")) {
                    errorMessage = errorMessage.replace("beneficiary.address.", "");
                }

                if (errorMessage && errorMessage.includes("beneficiary.bank_details.")) {
                    errorMessage = errorMessage.replace("beneficiary.bank_details.", "");
                }

                toast.error(`Error: ${errorMessage}`)
                setLoading(false)
            }
            else {
                toast.error(`Error: ${error.response.data.result.error}`)
                setLoading(false)
            }
            //setLoading(false)
        }
    }

    const [activeTab, setactiveTab] = useState(1)
    const [activeTabIn, setactiveTabIn] = useState(1)

    const changeTab = val => {
        setactiveTabIn(2)
    }

    const changeTab2 =()=>{
        setactiveTab(2)
    }

    const handleCancel = () => {
        // Reset form fields
        // resetData()
        setactiveTabIn(1)
    }

    const handleCancel1 =()=>{
        setactiveTab(1)
    }

    const changeCountry = async (value, form) => {
        const selectedCountry = countries.find(
            (country) => country.country_code == value
        );
        const transferOfMethodsData = selectedCountry.source_currency.map(
            (method) => {
                return {
                    value: method.toLowerCase(),
                    label: method.toUpperCase(),
                };
            }
        );
        setTransferMethods(transferOfMethodsData);
        form.setFieldValue("bankCountry", value);
        form.setFieldValue("swiftCode", "");
        form.setFieldValue("localSwiftCode", "");
        form.setFieldValue("accountRoutingValue", "");
    };

    const changeTransferMethods =  (value, form) => {
        form.setFieldValue("bankCodeType", value);
        form.setFieldValue("swiftCode", "");
        form.setFieldValue("localSwiftCode", "");
        form.setFieldValue("accountRoutingValue", "");
    };


    document.title = t("REGISTER_PAYEE_TPS_SOLUTIONS");

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs maintitle={t('TPS_SOLUTIONS')} title={t('PAYEE')} breadcrumbItem={t("REGISTER_PAYEE")}
                                 mainTitleLink="/" titleLink="/payee"/>

                    <Row>
                        <Col md={12}>
                            <Card>
                                <CardBody>
                                    <Nav tabs className="nav-tabs-custom-fill mb-1">
                                        <NavItem className="me-1">
                                            <NavLink
                                                className={registerPayeeState.registerPayeeActiveTab === '1' ? 'active cursor-pointer px-0' : 'cursor-pointer px-0'}
                                                onClick={() => {
                                                    toggleCustom('1');
                                                }}
                                            >
                                                <span>{t("REGISTER_INDIVIDUAL_PAYEE")}</span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={registerPayeeState.registerPayeeActiveTab === '2' ? 'active cursor-pointer px-0' : 'cursor-pointer px-0'}
                                                onClick={() => {
                                                    toggleCustom('2');
                                                }}
                                            >
                                                <span>{t("REGISTER_BUSINESS_PAYEE")}</span>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>

                                    <TabContent activeTab={registerPayeeState.registerPayeeActiveTab}
                                                className="text-muted py-3">
                                        <TabPane tabId="1">


                                                        <div className="form-horizontal form-wizard-wrapper wizard clearfix register-wizard custom-wizard">

                                                            <div className="content clearfix p-0 m-0">
                                                                <Formik
                                                                    enableReinitialize
                                                                    initialValues={{
                                                                        bankCountry: "",
                                                                        accountCurrency: "",
                                                                        bankCodeType: "",
                                                                        swiftCode: "",
                                                                        localSwiftCode: "",
                                                                        bankName: "",
                                                                        bankAccountNumber: "",
                                                                        bankAccountName: "",
                                                                        accountRoutingValue:""
                                                                       // firstName: "",
                                                                        //lastName: "",
                                                                        //nickName: "",
                                                                        //email: "",
                                                                        // country:"",
                                                                       //address: "",
                                                                        //city: "",
                                                                        //state: "",
                                                                        //postalCode: "",
                                                                    }}
                                                                    validationSchema={validationSchema}
                                                                    onSubmit={onSubmitRegisterIndividualPayeeBankAccountDetails}
                                                                >
                                                                    {({isSubmitting, values,setFieldValue}) => (
                                                                        <Form>

                                                                <TabContent activeTab={activeTabIn} className="body">
                                                                    <TabPane tabId={1}>
                                                                        <Card className="p-0 shadow-none">
                                                                            <Row className='d-flex justify-content-start'>
                                                                                <Col md={10} xl={8}>
                                                                                    <CardTitle tag="h5">
                                                                                        {t("BANK_ACCOUNT_DETAILS")}
                                                                                    </CardTitle>
                                                                                </Col>
                                                                            </Row>

                                                                            <CardBody className="px-0 py-1">
                                                                                <Row className='d-flex justify-content-start'>
                                                                                    <Col md={10} xl={8}>
                                                                                        <Row>

                                                                                            <Col md={6} xl={6}>
                                                                                                <FormGroup className="mb-3">
                                                                                                    <Label htmlFor="bankCountry">
                                                                                                        {t("BANK_COUNTRY")}
                                                                                                        <sup className="text-danger"> *</sup>
                                                                                                    </Label>
                                                                                                    <FormDropdown
                                                                                                        name="bankCountry"
                                                                                                        id="bankCountry"
                                                                                                        optionsValues={countries}
                                                                                                        changeDropdown={changeCountry}
                                                                                                        setFieldValue={setFieldValue}
                                                                                                        disabled={isAccountActive ? false : true}
                                                                                                    />
                                                                                                </FormGroup>
                                                                                            </Col>

                                                                                            <Col md={6} xl={6}>
                                                                                                <FormGroup className="mb-3">
                                                                                                    <Label htmlFor="transferMethod">
                                                                                                        {t("TRANSFER_METHOD")}
                                                                                                        <sup className="text-danger"> *</sup>
                                                                                                    </Label>
                                                                                                    <FormDropdown
                                                                                                        name="bankCodeType"
                                                                                                        id="bankCodeType"
                                                                                                        optionsValues={transferMethods}
                                                                                                        changeDropdown={changeTransferMethods}
                                                                                                        disabled={isAccountActive ? false : true}
                                                                                                        // changeDropdown={changePayee}
                                                                                                        // setFieldValue={setFieldValue}
                                                                                                    />
                                                                                                </FormGroup>
                                                                                            </Col>

                                                              

                                                                                            {
                                                                                                values.bankCodeType === "swift" ? (
                                                                                                        <Col md={6} xl={6}>
                                                                                                            <FormGroup className="mb-3">
                                                                                                                <Label htmlFor="swiftCode">
                                                                                                                    {t("SWIFT_CODE")}
                                                                                                                    <sup
                                                                                                                        className="text-danger"> *</sup>
                                                                                                                </Label>
                                                                                                                <FormTextField
                                                                                                                    name="swiftCode"
                                                                                                                    id="swiftCode"
                                                                                                                    placeholder={t("SWIFT_CODE")}
                                                                                                                    disabled={isAccountActive ? false : true}
                                                                                                                />
                                                                                                            </FormGroup>
                                                                                                        </Col>

                                                                                                    ) :
                                                                                                    null
                                                                                            }

                                                                                            {/* -------------------- Singapore ---------------------------------------------- */}

                                                                                            {
                                                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (
                                                                                                    (values?.bankCountry === "SG" && values?.bankCodeType?.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "local" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "fast") ?(
                                                                                                        <Col md={6} xl={6}>
                                                                                                            <FormGroup className="mb-3">
                                                                                                                <Label htmlFor="localSwiftCode">
                                                                                                                    {t("SWIFT_CODE")}
                                                                                                                    <sup
                                                                                                                        className="text-danger"> *</sup>
                                                                                                                </Label>
                                                                                                                <FormTextField
                                                                                                                    name="localSwiftCode"
                                                                                                                    id="localSwiftCode"
                                                                                                                    placeholder={t("SWIFT_CODE")}
                                                                                                                    disabled={isAccountActive ? false : true}
                                                                                                                />
                                                                                                            </FormGroup>
                                                                                                        </Col>
                                                                                                        ) : null
                                                                                                ): null
                                                                                            }

                                                                                            {
                                                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (
                                                                                                    (values?.bankCountry === "SG" && values?.bankCodeType?.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "local" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "giro") ? (
                                                                                                        <Col md={6} xl={6}>
                                                                                                            <FormGroup className="mb-3">
                                                                                                                <Label htmlFor="localSwiftCode">
                                                                                                                    {t("SWIFT_CODE")}
                                                                                                                    <sup
                                                                                                                        className="text-danger"> *</sup>
                                                                                                                </Label>
                                                                                                                <FormTextField
                                                                                                                    name="localSwiftCode"
                                                                                                                    id="localSwiftCode"
                                                                                                                    placeholder={t("SWIFT_CODE")}
                                                                                                                    disabled={isAccountActive ? false : true}
                                                                                                                />
                                                                                                            </FormGroup>
                                                                                                        </Col>
                                                                                                        ) : null
                                                                                                    ):
                                                                                                    null
                                                                                            }

                                                                                            {
                                                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (
                                                                                                    (values?.bankCountry === "SG" && values?.bankCodeType?.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "local" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "rtgs") ? (
                                                                                                        <Col md={6} xl={6}>
                                                                                                            <FormGroup className="mb-3">
                                                                                                                <Label htmlFor="localSwiftCode">
                                                                                                                    {t("SWIFT_CODE")}
                                                                                                                    <sup
                                                                                                                        className="text-danger"> *</sup>
                                                                                                                </Label>
                                                                                                                <FormTextField
                                                                                                                    name="localSwiftCode"
                                                                                                                    id="localSwiftCode"
                                                                                                                    placeholder={t("SWIFT_CODE")}
                                                                                                                    disabled={isAccountActive ? false : true}
                                                                                                                />
                                                                                                            </FormGroup>
                                                                                                        </Col>
                                                                                                        ) : null

                                                                                                    ):
                                                                                       null
                                                                                            }

                                                                                            {/* --------------------  Hong Kong ---------------------------------------------- */}

                                                                                            {
                                                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (
                                                                                                    (values?.bankCountry === "HK" && values?.bankCodeType.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "local" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "ach") ? (
                                                                                                        <Col md={6} xl={6}>
                                                                                                            <FormGroup className="mb-3">
                                                                                                                <Label htmlFor="accountRoutingValue">
                                                                                                                    {t("ACCOUNT_ROUTING_VALUE")}
                                                                                                                    <sup
                                                                                                                        className="text-danger"> *</sup>
                                                                                                                </Label>
                                                                                                                <FormTextField
                                                                                                                    name="accountRoutingValue"
                                                                                                                    id="accountRoutingValue"
                                                                                                                    placeholder={t("ACCOUNT_ROUTING_VALUE")}
                                                                                                                    disabled={isAccountActive ? false : true}
                                                                                                                />
                                                                                                            </FormGroup>
                                                                                                        </Col>
                                                                                                        ) : null

                                                                                                    ):
                                                                                                  null
                                                                                            }

                                                                                            {
                                                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (
                                                                                                    (values?.bankCountry === "HK" && values?.bankCodeType?.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "local" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "fps") ? (
                                                                                                        <Col md={6} xl={6}>
                                                                                                            <FormGroup className="mb-3">
                                                                                                                <Label htmlFor="accountRoutingValue">
                                                                                                                    {t("ACCOUNT_ROUTING_VALUE")}
                                                                                                                    <sup
                                                                                                                        className="text-danger"> *</sup>
                                                                                                                </Label>
                                                                                                                <FormTextField
                                                                                                                    name="accountRoutingValue"
                                                                                                                    id="accountRoutingValue"
                                                                                                                    placeholder={t("ACCOUNT_ROUTING_VALUE")}
                                                                                                                    disabled={isAccountActive ? false : true}
                                                                                                                />
                                                                                                            </FormGroup>
                                                                                                        </Col>
                                                                                                        ) : null

                                                                                                    ):
                                                                                                   null
                                                                                            }

                                                                                            {
                                                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (
                                                                                                    (values?.bankCountry === "HK" && values?.bankCodeType?.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "local" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "rtgs") ? (
                                                                                                        <Col md={6} xl={6}>
                                                                                                            <FormGroup className="mb-3">
                                                                                                                <Label htmlFor="localSwiftCode">
                                                                                                                    {t("SWIFT_CODE")}
                                                                                                                    <sup
                                                                                                                        className="text-danger"> *</sup>
                                                                                                                </Label>
                                                                                                                <FormTextField
                                                                                                                    name="localSwiftCode"
                                                                                                                    id="localSwiftCode"
                                                                                                                    placeholder={t("SWIFT_CODE")}
                                                                                                                    disabled={isAccountActive ? false : true}
                                                                                                                />
                                                                                                            </FormGroup>
                                                                                                        </Col>
                                                                                                        ) : null

                                                                                                    ):
                                                                                                    null
                                                                                            }

                                                                                            {/* --------------------  China ---------------------------------------------- */}

                                                                                            {
                                                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string') ? (
                                                                                                    (values?.bankCountry === "CN" && values?.bankCodeType.length > 0 && values?.bankCodeType === "local") ?(
                                                                                                        <Col md={6} xl={6}>
                                                                                                            <FormGroup className="mb-3">
                                                                                                                <Label htmlFor="accountRoutingValue">
                                                                                                                    {t("ACCOUNT_ROUTING_VALUE")}
                                                                                                                    <sup
                                                                                                                        className="text-danger"> *</sup>
                                                                                                                </Label>
                                                                                                                <FormTextField
                                                                                                                    name="accountRoutingValue"
                                                                                                                    id="accountRoutingValue"
                                                                                                                    placeholder={t("ACCOUNT_ROUTING_VALUE")}
                                                                                                                    disabled={isAccountActive ? false : true}
                                                                                                                />
                                                                                                            </FormGroup>
                                                                                                        </Col>
                                                                                                        ) : null

                                                                                                    ):
                                                                                                    null
                                                                                            }

                                                                                            {/* --------------------  United Kingdom ---------------------------------------------- */}

                                                                                            {
                                                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (
                                                                                                    (values?.bankCountry === "GB" && values?.bankCodeType.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "local" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "faster_payments") ? (
                                                                                                        <Col md={6} xl={6}>
                                                                                                            <FormGroup className="mb-3">
                                                                                                                <Label htmlFor="accountRoutingValue">
                                                                                                                    {t("ACCOUNT_ROUTING_VALUE")}
                                                                                                                    <sup
                                                                                                                        className="text-danger"> *</sup>
                                                                                                                </Label>
                                                                                                                <FormTextField
                                                                                                                    name="accountRoutingValue"
                                                                                                                    id="accountRoutingValue"
                                                                                                                    placeholder={t("ACCOUNT_ROUTING_VALUE")}
                                                                                                                    disabled={isAccountActive ? false : true}
                                                                                                                />
                                                                                                            </FormGroup>
                                                                                                        </Col>
                                                                                                        ) : null

                                                                                                    ):
                                                                                                    null
                                                                                            }

                                                                                            {/* --------------------  United State ---------------------------------------------- */}

                                                                                            {
                                                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (
                                                                                                    (values?.bankCountry === "US" && values?.bankCodeType?.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "local" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "ach") ? (
                                                                                                        <Col md={6} xl={6}>
                                                                                                            <FormGroup className="mb-3">
                                                                                                                <Label htmlFor="accountRoutingValue">
                                                                                                                    {t("ACCOUNT_ROUTING_VALUE")}
                                                                                                                    <sup
                                                                                                                        className="text-danger"> *</sup>
                                                                                                                </Label>
                                                                                                                <FormTextField
                                                                                                                    name="accountRoutingValue"
                                                                                                                    id="accountRoutingValue"
                                                                                                                    placeholder={t("ACCOUNT_ROUTING_VALUE")}
                                                                                                                    disabled={isAccountActive ? false : true}
                                                                                                                />
                                                                                                            </FormGroup>
                                                                                                        </Col>
                                                                                                        ) : null

                                                                                                    ):
                                                                                                    null
                                                                                            }

                                                                                            {
                                                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (
                                                                                                    (values?.bankCountry === "US" && values?.bankCodeType?.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "local" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "fedwire") ? (
                                                                                                        <Col md={6} xl={6}>
                                                                                                            <FormGroup className="mb-3">
                                                                                                                <Label htmlFor="accountRoutingValue">
                                                                                                                    {t("ACCOUNT_ROUTING_VALUE")}
                                                                                                                    <sup
                                                                                                                        className="text-danger"> *</sup>
                                                                                                                </Label>
                                                                                                                <FormTextField
                                                                                                                    name="accountRoutingValue"
                                                                                                                    id="accountRoutingValue"
                                                                                                                    placeholder={t("ACCOUNT_ROUTING_VALUE")}
                                                                                                                    disabled={isAccountActive ? false : true}
                                                                                                                />
                                                                                                            </FormGroup>
                                                                                                        </Col>
                                                                                                        ) : null

                                                                                                    ):
                                                                                                    null
                                                                                            }

                                                                                            {/*{*/}
                                                                                            {/*    (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (*/}
                                                                                            {/*        (values?.bankCountry === "US" && values?.bankCodeType?.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "local" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "paper_check") ? (*/}
                                                                                            {/*            <Col md={6} xl={6}>*/}
                                                                                            {/*                <FormGroup className="mb-3">*/}
                                                                                            {/*                    <Label htmlFor="accountRoutingValue">*/}
                                                                                            {/*                        {t("ACCOUNT_ROUTING_VALUE")}*/}
                                                                                            {/*                        <sup*/}
                                                                                            {/*                            className="text-danger"> *</sup>*/}
                                                                                            {/*                    </Label>*/}
                                                                                            {/*                    <FormTextField*/}
                                                                                            {/*                        name="accountRoutingValue"*/}
                                                                                            {/*                        id="accountRoutingValue"*/}
                                                                                            {/*                        placeholder={t("ACCOUNT_ROUTING_VALUE")}*/}
                                                                                            {/*                        disabled={isAccountActive ? false : true}*/}
                                                                                            {/*                    />*/}
                                                                                            {/*                </FormGroup>*/}
                                                                                            {/*            </Col>*/}
                                                                                            {/*            ) : null*/}

                                                                                            {/*        ):*/}
                                                                                            {/*        null*/}
                                                                                            {/*}*/}

                                                                                            {/* --------------------  Australia ---------------------------------------------- */}

                                                                                            {
                                                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (
                                                                                                    (values?.bankCountry === "AU" && values?.bankCodeType?.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "local" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "bpay") ? (
                                                                                                        <Col md={6} xl={6}>
                                                                                                            <FormGroup className="mb-3">
                                                                                                                <Label htmlFor="accountRoutingValue">
                                                                                                                    {t("ACCOUNT_ROUTING_VALUE")}
                                                                                                                    <sup
                                                                                                                        className="text-danger"> *</sup>
                                                                                                                </Label>
                                                                                                                <FormTextField
                                                                                                                    name="accountRoutingValue"
                                                                                                                    id="accountRoutingValue"
                                                                                                                    placeholder={t("ACCOUNT_ROUTING_VALUE")}
                                                                                                                    disabled={isAccountActive ? false : true}
                                                                                                                />
                                                                                                            </FormGroup>
                                                                                                        </Col>
                                                                                                        ) : null

                                                                                                    ):
                                                                                                    null
                                                                                            }

                                                                                            {
                                                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (
                                                                                                    (values?.bankCountry === "AU" && values.bankCodeType.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "local" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "bank_transfer") ? (
                                                                                                        <Col md={6} xl={6}>
                                                                                                            <FormGroup className="mb-3">
                                                                                                                <Label htmlFor="accountRoutingValue">
                                                                                                                    {t("ACCOUNT_ROUTING_VALUE")}
                                                                                                                    <sup
                                                                                                                        className="text-danger"> *</sup>
                                                                                                                </Label>
                                                                                                                <FormTextField
                                                                                                                    name="accountRoutingValue"
                                                                                                                    id="accountRoutingValue"
                                                                                                                    placeholder={t("ACCOUNT_ROUTING_VALUE")}
                                                                                                                    disabled={isAccountActive ? false : true}
                                                                                                                />
                                                                                                            </FormGroup>
                                                                                                        </Col>
                                                                                                        ) : null

                                                                                                    ):
                                                                                                    null
                                                                                            }

                                                                                            {/* --------------------  Japan ---------------------------------------------- */}

                                                                                            {
                                                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string') ? (
                                                                                                    (values?.bankCountry === "JP" && values?.bankCodeType.length > 0 && values?.bankCodeType === "local") ? (
                                                                                                        <Col md={6} xl={6}>
                                                                                                            <FormGroup className="mb-3">
                                                                                                                <Label htmlFor="accountRoutingValue">
                                                                                                                    {t("ACCOUNT_ROUTING_VALUE")}
                                                                                                                    <sup
                                                                                                                        className="text-danger"> *</sup>
                                                                                                                </Label>
                                                                                                                <FormTextField
                                                                                                                    name="accountRoutingValue"
                                                                                                                    id="accountRoutingValue"
                                                                                                                    placeholder={t("ACCOUNT_ROUTING_VALUE")}
                                                                                                                    disabled={isAccountActive ? false : true}
                                                                                                                />
                                                                                                            </FormGroup>
                                                                                                        </Col>
                                                                                                        ) : null

                                                                                                    ):
                                                                                                    null
                                                                                            }


                                                                                            {/* --------------------  Canada ---------------------------------------------- */}

                                                                                            {
                                                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string') ? (
                                                                                                    (values?.bankCountry === "CA" && values?.bankCodeType.length > 0 && values?.bankCodeType === "local") ? (
                                                                                                        <Col md={6} xl={6}>
                                                                                                            <FormGroup className="mb-3">
                                                                                                                <Label htmlFor="accountRoutingValue">
                                                                                                                    {t("ACCOUNT_ROUTING_VALUE")}
                                                                                                                    <sup
                                                                                                                        className="text-danger"> *</sup>
                                                                                                                </Label>
                                                                                                                <FormTextField
                                                                                                                    name="accountRoutingValue"
                                                                                                                    id="accountRoutingValue"
                                                                                                                    placeholder={t("ACCOUNT_ROUTING_VALUE")}
                                                                                                                    disabled={isAccountActive ? false : true}
                                                                                                                />
                                                                                                            </FormGroup>
                                                                                                        </Col>
                                                                                                        ) : null
                                                                                                    ):
                                                                                                    null
                                                                                            }

                                                                                            {/* --------------------  India ---------------------------------------------- */}

                                                                                            {
                                                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string') ? (
                                                                                                    (values?.bankCountry === "IN" && values?.bankCodeType.length > 0 && values?.bankCodeType === "local") ? (
                                                                                                        <Col md={6} xl={6}>
                                                                                                            <FormGroup className="mb-3">
                                                                                                                <Label htmlFor="accountRoutingValue">
                                                                                                                    {t("ACCOUNT_ROUTING_VALUE")}
                                                                                                                    <sup
                                                                                                                        className="text-danger"> *</sup>
                                                                                                                </Label>
                                                                                                                <FormTextField
                                                                                                                    name="accountRoutingValue"
                                                                                                                    id="accountRoutingValue"
                                                                                                                    placeholder={t("ACCOUNT_ROUTING_VALUE")}
                                                                                                                    disabled={isAccountActive ? false : true}
                                                                                                                />
                                                                                                            </FormGroup>
                                                                                                        </Col>
                                                                                                        ) : null

                                                                                                    ):
                                                                                                    null
                                                                                            }


                                                                                            <Col md={6} xl={6}>
                                                                                                <FormGroup className="mb-3">
                                                                                                    <Label
                                                                                                        htmlFor="accountCurrency">{t("ACCOUNT_CURRENCY")}<sup
                                                                                                        className="text-danger"> *</sup></Label>
                                                                                                    <FormDropdown name="accountCurrency"
                                                                                                                  id="accountCurrency"
                                                                                                                  placeholder={t("ACCOUNT_CURRENCY")}
                                                                                                                  optionsValues={isBankCurrency}
                                                                                                                  disabled={isAccountActive ? false : true}
                                                                                                    />
                                                                                                </FormGroup>
                                                                                            </Col>

                                                                                            <Col md={6} xl={6}>
                                                                                                <FormGroup className="mb-3">
                                                                                                    <Label htmlFor="bankName">
                                                                                                        {t("BANK_NAME")}
                                                                                                        <sup className="text-danger"> *</sup>
                                                                                                    </Label>
                                                                                                    <FormTextField
                                                                                                        name="bankName"
                                                                                                        id="bankName"
                                                                                                        placeholder={t("BANK_NAME")}
                                                                                                        disabled={isAccountActive ? false : true}
                                                                                                    />
                                                                                                </FormGroup>
                                                                                            </Col>

                                                                                            <Col md={6} xl={6}>
                                                                                                <FormGroup className="mb-3">
                                                                                                    <Label htmlFor="bankAccountNumber">
                                                                                                        {t("BANK_ACCOUNT_NUMBER")}
                                                                                                        <sup className="text-danger"> *</sup>
                                                                                                    </Label>
                                                                                                    <FormTextField
                                                                                                        type="text"
                                                                                                        name="bankAccountNumber"
                                                                                                        id="bankAccountNumber"
                                                                                                        placeholder={t("BANK_ACCOUNT_NUMBER")}
                                                                                                        disabled={isAccountActive ? false : true}
                                                                                                    />
                                                                                                </FormGroup>
                                                                                            </Col>

                                                                                            <Col md={6} xl={6}>
                                                                                                <FormGroup className="mb-3">
                                                                                                    <Label htmlFor="bankAccountName">
                                                                                                        {t("BANK_ACCOUNT_NAME")}
                                                                                                        <sup className="text-danger"> *</sup>
                                                                                                    </Label>
                                                                                                    <FormTextField
                                                                                                        name="bankAccountName"
                                                                                                        id="bankAccountName"
                                                                                                        placeholder={t("BANK_ACCOUNT_NAME")}
                                                                                                        disabled={isAccountActive ? false : true}
                                                                                                    />
                                                                                                </FormGroup>
                                                                                            </Col>

                                                                                            <Col md={12} xl={12}>
                                                                                                <FormGroup className="mb-3">
                                                                                                    <Label htmlFor="transferMethod">

                                                                                                        { values.bankCodeType == "" ? "" : values.bankCodeType.startsWith("swift") ?  <span style={{ color: "#cc3300" }}>{process.env.REACT_APP_DEDUCT_RATE_SWIFT} {process.env.REACT_APP_DEDUCT_RATE_CURRENCY} amount may deduct from each transaction for SWIFT transfer method.</span> 
                                                                                                        : <span style={{ color: "#cc3300" }}>{process.env.REACT_APP_DEDUCT_RATE_LOCAL} {process.env.REACT_APP_DEDUCT_RATE_CURRENCY} amount may deduct from each transaction for LOCAL transfer method.</span>}
                                                                                                    </Label>
                                                                                                </FormGroup>
                                                                                            </Col>

                                                                                            <Col md={12} xl={12} className="d-flex justify-content-end align-items-center gap-3 mt-3">
                                                                                                <Button disabled={isAccountActive ? false : true} className="btn-primary w-md" type="submit">{t("NEXT")}</Button>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Col>
                                                                                </Row>

                                                                            </CardBody>
                                                                        </Card>
                                                                    </TabPane>
                                                                </TabContent>
                                                    </Form>
                                                    )}
                                            </Formik>
                                                                <Formik
                                                                    enableReinitialize
                                                                    initialValues={{
                                                                      //  bankCountry: "",
                                                                     //   accountCurrency: "",
                                                                      //  bankCodeType: "",
                                                                      //  swiftCode: "",
                                                                       // bankName: "",
                                                                      //  bankAccountNumber: "",
                                                                       // bankAccountName: "",
                                                                        firstName: "",
                                                                        lastName: "",
                                                                        nickName: "",
                                                                        email: "",
                                                                        // country:"",
                                                                        address: "",
                                                                        city: "",
                                                                        state: "",
                                                                        postalCode: "",
                                                                    }}
                                                                    validationSchema={validationSchemaIn2}
                                                                    onSubmit={onSubmitRegisterIndividualPayee}
                                                                >
                                                                    {({isSubmitting, values,setFieldValue}) => (
                                                                        <Form>
                                                                <TabContent activeTab={activeTabIn} className="body">
                                                                    <TabPane tabId={2}>
                                                                        <Card className="p-0 shadow-none">
                                                                            <Row className='d-flex justify-content-start'>
                                                                                <Col md={10} xl={8}>
                                                                                    <CardTitle tag="h5">
                                                                                        {t("PERSONAL_DETAILS")}
                                                                                    </CardTitle>
                                                                                </Col>
                                                                            </Row>

                                                                            <CardBody className="px-0 py-1">
                                                                                <Row className='d-flex justify-content-start'>
                                                                                    <Col md={10} xl={8}>
                                                                                        <Row>

                                                                                            <Col md={6} xl={6}>
                                                                                                <FormGroup className="mb-3">
                                                                                                    <Label htmlFor="firstName">
                                                                                                        {t("FIRST_NAME")}
                                                                                                        <sup className="text-danger"> *</sup>
                                                                                                    </Label>
                                                                                                    <FormTextField
                                                                                                        name="firstName"
                                                                                                        id="firstName"
                                                                                                        placeholder={t("FIRST_NAME")}
                                                                                                        disabled={isAccountActive ? false : true}
                                                                                                    />
                                                                                                </FormGroup>
                                                                                            </Col>

                                                                                            <Col md={6} xl={6}>
                                                                                                <FormGroup className="mb-3">
                                                                                                    <Label htmlFor="lastName">
                                                                                                        {t("LAST_NAME")}
                                                                                                        <sup className="text-danger"> *</sup>
                                                                                                    </Label>
                                                                                                    <FormTextField
                                                                                                        name="lastName"
                                                                                                        id="lastName"
                                                                                                        placeholder={t("LAST_NAME")}
                                                                                                        disabled={isAccountActive ? false : true}
                                                                                                    />
                                                                                                </FormGroup>
                                                                                            </Col>


                                                                                            <Col md={6} xl={6}>
                                                                                                <FormGroup className="mb-3">
                                                                                                    <Label htmlFor="nickName">
                                                                                                        {t("NICK_NAME")}
                                                                                                        <sup className="text-danger"> *</sup>
                                                                                                    </Label>
                                                                                                    <FormTextField
                                                                                                        name="nickName"
                                                                                                        id="nickName"
                                                                                                        placeholder={t("NICK_NAME")}
                                                                                                        disabled={isAccountActive ? false : true}
                                                                                                    />
                                                                                                </FormGroup>
                                                                                            </Col>

                                                                                            <Col md={6} xl={6}>
                                                                                                <FormGroup className="mb-3">
                                                                                                    <Label htmlFor="email">
                                                                                                        {t("EMAIL")}
                                                                                                        <sup className="text-danger"> *</sup>
                                                                                                    </Label>
                                                                                                    <FormTextField
                                                                                                        name="email"
                                                                                                        id="email"
                                                                                                        placeholder={t("EMAIL")}
                                                                                                        disabled={isAccountActive ? false : true}
                                                                                                    />
                                                                                                </FormGroup>
                                                                                            </Col>

                                                                                            <Col md={6} xl={6}>
                                                                                                <FormGroup className="mb-3">
                                                                                                    <Label htmlFor="address">
                                                                                                        {t("ADDRESS")}
                                                                                                        <sup className="text-danger"> *</sup>
                                                                                                    </Label>
                                                                                                    <FormTextField
                                                                                                        name="address"
                                                                                                        id="address"
                                                                                                        placeholder={t("ADDRESS")}
                                                                                                        disabled={isAccountActive ? false : true}
                                                                                                    />
                                                                                                </FormGroup>
                                                                                            </Col>

                                                                                            <Col md={6} xl={6}>
                                                                                                <FormGroup className="mb-3">
                                                                                                    <Label htmlFor="city">
                                                                                                        {t("CITY")}
                                                                                                        <sup className="text-danger"> *</sup>
                                                                                                    </Label>
                                                                                                    <FormTextField
                                                                                                        name="city"
                                                                                                        id="city"
                                                                                                        placeholder={t("CITY")}
                                                                                                        disabled={isAccountActive ? false : true}
                                                                                                    />
                                                                                                </FormGroup>
                                                                                            </Col>

                                                                                            <Col md={6} xl={6}>
                                                                                                <FormGroup className="mb-3">
                                                                                                    <Label htmlFor="state">
                                                                                                        {t("STATE")}
                                                                                                        <sup className="text-danger"> *</sup>
                                                                                                    </Label>
                                                                                                    <FormTextField
                                                                                                        name="state"
                                                                                                        id="state"
                                                                                                        placeholder={t("STATE")}
                                                                                                        disabled={isAccountActive ? false : true}
                                                                                                    />
                                                                                                </FormGroup>
                                                                                            </Col>

                                                                                            <Col md={6} xl={6}>
                                                                                                <FormGroup className="mb-3">
                                                                                                    <Label htmlFor="postalCode">
                                                                                                        {t("POSTAL_CODE")}
                                                                                                        <sup className="text-danger"> *</sup>
                                                                                                    </Label>
                                                                                                    <FormTextField
                                                                                                        name="postalCode"
                                                                                                        id="postalCode"
                                                                                                        placeholder={t("POSTAL_CODE")}
                                                                                                        disabled={isAccountActive ? false : true}
                                                                                                    />
                                                                                                </FormGroup>
                                                                                            </Col>

                                                                                            <Col md={12} xl={12}
                                                                                                 className="d-flex justify-content-end gap-3 mt-3">
                                                                                                <Button className="btn-secondary w-md"
                                                                                                        type="button" onClick={handleCancel}>{t("BACK")}</Button>
                                                                                                <Button disabled={isAccountActive ? false : true} className="btn-primary w-md"
                                                                                                        type="submit">
                                                                                                    { loading ? (
                                                                                                        <Spinner size="sm">
                                                                                                            Loading...
                                                                                                        </Spinner>
                                                                                                    ) : "" }
                                                                                                    {' '}{' '}{t("SUBMIT")}</Button>
                                                                                            </Col>

                                                                                        </Row>
                                                                                    </Col>
                                                                                </Row>



                                                                            </CardBody>
                                                                        </Card>
                                                                    </TabPane>
                                                                </TabContent>
                                                                        </Form>
                                                                    )}
                                                                </Formik>
                                                            </div>
                                                        </div>



                                        </TabPane>
                                        <TabPane tabId="2">

                                                        <div className="form-horizontal form-wizard-wrapper wizard clearfix register-wizard custom-wizard">

                                                            <div className="content clearfix p-0 m-0">
                                                                <Formik
                                                                    enableReinitialize
                                                                    initialValues={{
                                                                        bankCountry: "",
                                                                        accountCurrency: "",
                                                                        bankCodeType: "",
                                                                        swiftCode: "",
                                                                        localSwiftCode: "",
                                                                        bankName: "",
                                                                        bankAccountNumber: "",
                                                                        bankAccountName: "",
                                                                        accountRoutingValue:""
                                                                       // businessName: "",
                                                                       // nickName: "",
                                                                     //   email: "",
                                                                        // country:"",
                                                                       // address: "",
                                                                       // city: "",
                                                                       // state: "",
                                                                       //postalCode: "",
                                                                        //legalRepFName: "",
                                                                        //legalRepLName: "",
                                                                    }}
                                                                    validationSchema={validationSchemaRegisterBusinessPayee}
                                                                    onSubmit={onSubmitRegisterBusinessPayeeBankAccountDetails}
                                                                >
                                                                    {({isSubmitting, values,setFieldValue}) => (
                                                                        <Form>
                                                                <TabContent activeTab={activeTab} className="body">
                                                                    <TabPane tabId={1}>
                                                                        <Card className="p-0 shadow-none">
                                                                            <Row className='d-flex justify-content-start'>
                                                                                <Col md={10} xl={8}>
                                                                                    <CardTitle tag="h5">
                                                                                        {t("BANK_ACCOUNT_DETAILS")}
                                                                                    </CardTitle>
                                                                                </Col></Row>
                                                                            <CardBody className="px-0 py-1">

                                                                                <Row className='d-flex justify-content-start'>
                                                                                    <Col md={10} xl={8}>
                                                                                        <Row>


                                                                                            <Col md={6} xl={6}>
                                                                                                <FormGroup className="mb-3">
                                                                                                    <Label htmlFor="bankCountry">
                                                                                                        {t("BANK_COUNTRY")}
                                                                                                        <sup className="text-danger"> *</sup>
                                                                                                    </Label>
                                                                                                    <FormDropdown
                                                                                                        name="bankCountry"
                                                                                                        id="bankCountry"
                                                                                                        optionsValues={countries}
                                                                                                        changeDropdown={changeCountry}
                                                                                                        setFieldValue={setFieldValue}
                                                                                                        disabled={isAccountActive ? false : true}
                                                                                                    />
                                                                                                </FormGroup>
                                                                                            </Col>

                                                                                            <Col md={6} xl={6}>
                                                                                                <FormGroup className="mb-3">
                                                                                                    <Label htmlFor="transferMethod">
                                                                                                        {t("TRANSFER_METHOD")}
                                                                                                        <sup className="text-danger"> *</sup>
                                                                                                    </Label>
                                                                                                    <FormDropdown
                                                                                                        name="bankCodeType"
                                                                                                        id="bankCodeType"
                                                                                                        optionsValues={transferMethods}
                                                                                                        changeDropdown={changeTransferMethods}
                                                                                                        disabled={isAccountActive ? false : true}
                                                                                                        // changeDropdown={changePayee}
                                                                                                        setFieldValue={setFieldValue}
                                                                                                    />
                                                                                                </FormGroup>
                                                                                            </Col>

                                                                                            {
                                                                                                values.bankCodeType === "swift" ? (
                                                                                                        <Col md={6} xl={6}>
                                                                                                            <FormGroup className="mb-3">
                                                                                                                <Label htmlFor="swiftCode">
                                                                                                                    {t("SWIFT_CODE")}
                                                                                                                    <sup
                                                                                                                        className="text-danger"> *</sup>
                                                                                                                </Label>
                                                                                                                <FormTextField
                                                                                                                    name="swiftCode"
                                                                                                                    id="swiftCode"
                                                                                                                    placeholder={t("SWIFT_CODE")}
                                                                                                                    disabled={isAccountActive ? false : true}
                                                                                                                />
                                                                                                            </FormGroup>
                                                                                                        </Col>

                                                                                                    ) :
                                                                                                    (" ")
                                                                                            }

                                                                                            {/* -------------------- Singapore ---------------------------------------------- */}

                                                                                            {
                                                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (
                                                                                                    (values?.bankCountry === "SG" && values?.bankCodeType?.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "local" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "fast") ?(
                                                                                                        <Col md={6} xl={6}>
                                                                                                            <FormGroup className="mb-3">
                                                                                                                <Label htmlFor="localSwiftCode">
                                                                                                                    {t("SWIFT_CODE")}
                                                                                                                    <sup
                                                                                                                        className="text-danger"> *</sup>
                                                                                                                </Label>
                                                                                                                <FormTextField
                                                                                                                    name="localSwiftCode"
                                                                                                                    id="localSwiftCode"
                                                                                                                    placeholder={t("SWIFT_CODE")}
                                                                                                                    disabled={isAccountActive ? false : true}
                                                                                                                />
                                                                                                            </FormGroup>
                                                                                                        </Col>
                                                                                                    ) : null
                                                                                                ): null
                                                                                            }

                                                                                            {
                                                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (
                                                                                                        (values?.bankCountry === "SG" && values?.bankCodeType?.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "local" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "giro") ? (
                                                                                                            <Col md={6} xl={6}>
                                                                                                                <FormGroup className="mb-3">
                                                                                                                    <Label htmlFor="localSwiftCode">
                                                                                                                        {t("SWIFT_CODE")}
                                                                                                                        <sup
                                                                                                                            className="text-danger"> *</sup>
                                                                                                                    </Label>
                                                                                                                    <FormTextField
                                                                                                                        name="localSwiftCode"
                                                                                                                        id="localSwiftCode"
                                                                                                                        placeholder={t("SWIFT_CODE")}
                                                                                                                        disabled={isAccountActive ? false : true}
                                                                                                                    />
                                                                                                                </FormGroup>
                                                                                                            </Col>
                                                                                                        ) : null
                                                                                                    ):
                                                                                                    null
                                                                                            }

                                                                                            {
                                                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (
                                                                                                        (values?.bankCountry === "SG" && values?.bankCodeType?.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "local" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "rtgs") ? (
                                                                                                            <Col md={6} xl={6}>
                                                                                                                <FormGroup className="mb-3">
                                                                                                                    <Label htmlFor="localSwiftCode">
                                                                                                                        {t("SWIFT_CODE")}
                                                                                                                        <sup
                                                                                                                            className="text-danger"> *</sup>
                                                                                                                    </Label>
                                                                                                                    <FormTextField
                                                                                                                        name="localSwiftCode"
                                                                                                                        id="localSwiftCode"
                                                                                                                        placeholder={t("SWIFT_CODE")}
                                                                                                                        disabled={isAccountActive ? false : true}
                                                                                                                    />
                                                                                                                </FormGroup>
                                                                                                            </Col>
                                                                                                        ) : null

                                                                                                    ):
                                                                                                    null
                                                                                            }

                                                                                            {/* --------------------  Hong Kong ---------------------------------------------- */}

                                                                                            {
                                                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (
                                                                                                        (values?.bankCountry === "HK" && values?.bankCodeType.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "local" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "ach") ? (
                                                                                                            <Col md={6} xl={6}>
                                                                                                                <FormGroup className="mb-3">
                                                                                                                    <Label htmlFor="accountRoutingValue">
                                                                                                                        {t("ACCOUNT_ROUTING_VALUE")}
                                                                                                                        <sup
                                                                                                                            className="text-danger"> *</sup>
                                                                                                                    </Label>
                                                                                                                    <FormTextField
                                                                                                                        name="accountRoutingValue"
                                                                                                                        id="accountRoutingValue"
                                                                                                                        placeholder={t("ACCOUNT_ROUTING_VALUE")}
                                                                                                                        disabled={isAccountActive ? false : true}
                                                                                                                    />
                                                                                                                </FormGroup>
                                                                                                            </Col>
                                                                                                        ) : null

                                                                                                    ):
                                                                                                    null
                                                                                            }

                                                                                            {
                                                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (
                                                                                                        (values?.bankCountry === "HK" && values?.bankCodeType?.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "local" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "fps") ? (
                                                                                                            <Col md={6} xl={6}>
                                                                                                                <FormGroup className="mb-3">
                                                                                                                    <Label htmlFor="accountRoutingValue">
                                                                                                                        {t("ACCOUNT_ROUTING_VALUE")}
                                                                                                                        <sup
                                                                                                                            className="text-danger"> *</sup>
                                                                                                                    </Label>
                                                                                                                    <FormTextField
                                                                                                                        name="accountRoutingValue"
                                                                                                                        id="accountRoutingValue"
                                                                                                                        placeholder={t("ACCOUNT_ROUTING_VALUE")}
                                                                                                                        disabled={isAccountActive ? false : true}
                                                                                                                    />
                                                                                                                </FormGroup>
                                                                                                            </Col>
                                                                                                        ) : null

                                                                                                    ):
                                                                                                    null
                                                                                            }

                                                                                            {
                                                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (
                                                                                                        (values?.bankCountry === "HK" && values?.bankCodeType?.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "local" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "rtgs") ? (
                                                                                                            <Col md={6} xl={6}>
                                                                                                                <FormGroup className="mb-3">
                                                                                                                    <Label htmlFor="localSwiftCode">
                                                                                                                        {t("SWIFT_CODE")}
                                                                                                                        <sup
                                                                                                                            className="text-danger"> *</sup>
                                                                                                                    </Label>
                                                                                                                    <FormTextField
                                                                                                                        name="localSwiftCode"
                                                                                                                        id="localSwiftCode"
                                                                                                                        placeholder={t("SWIFT_CODE")}
                                                                                                                        disabled={isAccountActive ? false : true}
                                                                                                                    />
                                                                                                                </FormGroup>
                                                                                                            </Col>
                                                                                                        ) : null

                                                                                                    ):
                                                                                                    null
                                                                                            }

                                                                                            {/* --------------------  China ---------------------------------------------- */}

                                                                                            {
                                                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string') ? (
                                                                                                        (values?.bankCountry === "CN" && values?.bankCodeType.length > 0 && values?.bankCodeType === "local") ?(
                                                                                                            <Col md={6} xl={6}>
                                                                                                                <FormGroup className="mb-3">
                                                                                                                    <Label htmlFor="accountRoutingValue">
                                                                                                                        {t("ACCOUNT_ROUTING_VALUE")}
                                                                                                                        <sup
                                                                                                                            className="text-danger"> *</sup>
                                                                                                                    </Label>
                                                                                                                    <FormTextField
                                                                                                                        name="accountRoutingValue"
                                                                                                                        id="accountRoutingValue"
                                                                                                                        placeholder={t("ACCOUNT_ROUTING_VALUE")}
                                                                                                                        disabled={isAccountActive ? false : true}
                                                                                                                    />
                                                                                                                </FormGroup>
                                                                                                            </Col>
                                                                                                        ) : null

                                                                                                    ):
                                                                                                    null
                                                                                            }

                                                                                            {/* --------------------  United Kingdom ---------------------------------------------- */}

                                                                                            {
                                                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (
                                                                                                        (values?.bankCountry === "GB" && values?.bankCodeType.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "local" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "faster_payments") ? (
                                                                                                            <Col md={6} xl={6}>
                                                                                                                <FormGroup className="mb-3">
                                                                                                                    <Label htmlFor="accountRoutingValue">
                                                                                                                        {t("ACCOUNT_ROUTING_VALUE")}
                                                                                                                        <sup
                                                                                                                            className="text-danger"> *</sup>
                                                                                                                    </Label>
                                                                                                                    <FormTextField
                                                                                                                        name="accountRoutingValue"
                                                                                                                        id="accountRoutingValue"
                                                                                                                        placeholder={t("ACCOUNT_ROUTING_VALUE")}
                                                                                                                        disabled={isAccountActive ? false : true}
                                                                                                                    />
                                                                                                                </FormGroup>
                                                                                                            </Col>
                                                                                                        ) : null

                                                                                                    ):
                                                                                                    null
                                                                                            }

                                                                                            {/* --------------------  United State ---------------------------------------------- */}

                                                                                            {
                                                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (
                                                                                                        (values?.bankCountry === "US" && values?.bankCodeType?.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "local" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "ach") ? (
                                                                                                            <Col md={6} xl={6}>
                                                                                                                <FormGroup className="mb-3">
                                                                                                                    <Label htmlFor="accountRoutingValue">
                                                                                                                        {t("ACCOUNT_ROUTING_VALUE")}
                                                                                                                        <sup
                                                                                                                            className="text-danger"> *</sup>
                                                                                                                    </Label>
                                                                                                                    <FormTextField
                                                                                                                        name="accountRoutingValue"
                                                                                                                        id="accountRoutingValue"
                                                                                                                        placeholder={t("ACCOUNT_ROUTING_VALUE")}
                                                                                                                        disabled={isAccountActive ? false : true}
                                                                                                                    />
                                                                                                                </FormGroup>
                                                                                                            </Col>
                                                                                                        ) : null

                                                                                                    ):
                                                                                                    null
                                                                                            }

                                                                                            {
                                                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (
                                                                                                        (values?.bankCountry === "US" && values?.bankCodeType?.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "local" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "fedwire") ? (
                                                                                                            <Col md={6} xl={6}>
                                                                                                                <FormGroup className="mb-3">
                                                                                                                    <Label htmlFor="accountRoutingValue">
                                                                                                                        {t("ACCOUNT_ROUTING_VALUE")}
                                                                                                                        <sup
                                                                                                                            className="text-danger"> *</sup>
                                                                                                                    </Label>
                                                                                                                    <FormTextField
                                                                                                                        name="accountRoutingValue"
                                                                                                                        id="accountRoutingValue"
                                                                                                                        placeholder={t("ACCOUNT_ROUTING_VALUE")}
                                                                                                                        disabled={isAccountActive ? false : true}
                                                                                                                    />
                                                                                                                </FormGroup>
                                                                                                            </Col>
                                                                                                        ) : null

                                                                                                    ):
                                                                                                    null
                                                                                            }

                                                                                            {/*{*/}
                                                                                            {/*    (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (*/}
                                                                                            {/*            (values?.bankCountry === "US" && values?.bankCodeType?.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "local" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "paper_check") ? (*/}
                                                                                            {/*                <Col md={6} xl={6}>*/}
                                                                                            {/*                    <FormGroup className="mb-3">*/}
                                                                                            {/*                        <Label htmlFor="accountRoutingValue">*/}
                                                                                            {/*                            {t("ACCOUNT_ROUTING_VALUE")}*/}
                                                                                            {/*                            <sup*/}
                                                                                            {/*                                className="text-danger"> *</sup>*/}
                                                                                            {/*                        </Label>*/}
                                                                                            {/*                        <FormTextField*/}
                                                                                            {/*                            name="accountRoutingValue"*/}
                                                                                            {/*                            id="accountRoutingValue"*/}
                                                                                            {/*                            placeholder={t("ACCOUNT_ROUTING_VALUE")}*/}
                                                                                            {/*                            disabled={isAccountActive ? false : true}*/}
                                                                                            {/*                        />*/}
                                                                                            {/*                    </FormGroup>*/}
                                                                                            {/*                </Col>*/}
                                                                                            {/*            ) : null*/}

                                                                                            {/*        ):*/}
                                                                                            {/*        null*/}
                                                                                            {/*}*/}

                                                                                            {/* --------------------  Australia ---------------------------------------------- */}

                                                                                            {
                                                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (
                                                                                                        (values?.bankCountry === "AU" && values?.bankCodeType?.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "local" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "bpay") ? (
                                                                                                            <Col md={6} xl={6}>
                                                                                                                <FormGroup className="mb-3">
                                                                                                                    <Label htmlFor="accountRoutingValue">
                                                                                                                        {t("ACCOUNT_ROUTING_VALUE")}
                                                                                                                        <sup
                                                                                                                            className="text-danger"> *</sup>
                                                                                                                    </Label>
                                                                                                                    <FormTextField
                                                                                                                        name="accountRoutingValue"
                                                                                                                        id="accountRoutingValue"
                                                                                                                        placeholder={t("ACCOUNT_ROUTING_VALUE")}
                                                                                                                        disabled={isAccountActive ? false : true}
                                                                                                                    />
                                                                                                                </FormGroup>
                                                                                                            </Col>
                                                                                                        ) : null

                                                                                                    ):
                                                                                                    null
                                                                                            }

                                                                                            {
                                                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (
                                                                                                        (values?.bankCountry === "AU" && values.bankCodeType.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "local" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "bank_transfer") ? (
                                                                                                            <Col md={6} xl={6}>
                                                                                                                <FormGroup className="mb-3">
                                                                                                                    <Label htmlFor="accountRoutingValue">
                                                                                                                        {t("ACCOUNT_ROUTING_VALUE")}
                                                                                                                        <sup
                                                                                                                            className="text-danger"> *</sup>
                                                                                                                    </Label>
                                                                                                                    <FormTextField
                                                                                                                        name="accountRoutingValue"
                                                                                                                        id="accountRoutingValue"
                                                                                                                        placeholder={t("ACCOUNT_ROUTING_VALUE")}
                                                                                                                        disabled={isAccountActive ? false : true}
                                                                                                                    />
                                                                                                                </FormGroup>
                                                                                                            </Col>
                                                                                                        ) : null

                                                                                                    ):
                                                                                                    null
                                                                                            }

                                                                                            {/* --------------------  Japan ---------------------------------------------- */}

                                                                                            {
                                                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string') ? (
                                                                                                        (values?.bankCountry === "JP" && values?.bankCodeType.length > 0 && values?.bankCodeType === "local") ? (
                                                                                                            <Col md={6} xl={6}>
                                                                                                                <FormGroup className="mb-3">
                                                                                                                    <Label htmlFor="accountRoutingValue">
                                                                                                                        {t("ACCOUNT_ROUTING_VALUE")}
                                                                                                                        <sup
                                                                                                                            className="text-danger"> *</sup>
                                                                                                                    </Label>
                                                                                                                    <FormTextField
                                                                                                                        name="accountRoutingValue"
                                                                                                                        id="accountRoutingValue"
                                                                                                                        placeholder={t("ACCOUNT_ROUTING_VALUE")}
                                                                                                                        disabled={isAccountActive ? false : true}
                                                                                                                    />
                                                                                                                </FormGroup>
                                                                                                            </Col>
                                                                                                        ) : null

                                                                                                    ):
                                                                                                    null
                                                                                            }


                                                                                            {/* --------------------  Canada ---------------------------------------------- */}

                                                                                            {
                                                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string') ? (
                                                                                                        (values?.bankCountry === "CA" && values?.bankCodeType.length > 0 && values?.bankCodeType === "local") ? (
                                                                                                            <Col md={6} xl={6}>
                                                                                                                <FormGroup className="mb-3">
                                                                                                                    <Label htmlFor="accountRoutingValue">
                                                                                                                        {t("ACCOUNT_ROUTING_VALUE")}
                                                                                                                        <sup
                                                                                                                            className="text-danger"> *</sup>
                                                                                                                    </Label>
                                                                                                                    <FormTextField
                                                                                                                        name="accountRoutingValue"
                                                                                                                        id="accountRoutingValue"
                                                                                                                        placeholder={t("ACCOUNT_ROUTING_VALUE")}
                                                                                                                        disabled={isAccountActive ? false : true}
                                                                                                                    />
                                                                                                                </FormGroup>
                                                                                                            </Col>
                                                                                                        ) : null
                                                                                                    ):
                                                                                                    null
                                                                                            }

                                                                                            {/* --------------------  India ---------------------------------------------- */}

                                                                                            {
                                                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string') ? (
                                                                                                        (values?.bankCountry === "IN" && values?.bankCodeType.length > 0 && values?.bankCodeType === "local") ? (
                                                                                                            <Col md={6} xl={6}>
                                                                                                                <FormGroup className="mb-3">
                                                                                                                    <Label htmlFor="accountRoutingValue">
                                                                                                                        {t("ACCOUNT_ROUTING_VALUE")}
                                                                                                                        <sup
                                                                                                                            className="text-danger"> *</sup>
                                                                                                                    </Label>
                                                                                                                    <FormTextField
                                                                                                                        name="accountRoutingValue"
                                                                                                                        id="accountRoutingValue"
                                                                                                                        placeholder={t("ACCOUNT_ROUTING_VALUE")}
                                                                                                                        disabled={isAccountActive ? false : true}
                                                                                                                    />
                                                                                                                </FormGroup>
                                                                                                            </Col>
                                                                                                        ) : null

                                                                                                    ):
                                                                                                    null
                                                                                            }

                                                                                            <Col md={6} xl={6}>
                                                                                                <FormGroup className="mb-3">
                                                                                                    <Label
                                                                                                        htmlFor="accountCurrency">{t("ACCOUNT_CURRENCY")}<sup
                                                                                                        className="text-danger"> *</sup></Label>
                                                                                                    <FormDropdown name="accountCurrency"
                                                                                                                  id="accountCurrency"
                                                                                                                  placeholder={t("ACCOUNT_CURRENCY")}
                                                                                                                  optionsValues={isBankCurrency}
                                                                                                                  disabled={isAccountActive ? false : true}
                                                                                                    />
                                                                                                </FormGroup>
                                                                                            </Col>

                                                                                            <Col md={6} xl={6}>
                                                                                                <FormGroup className="mb-3">
                                                                                                    <Label htmlFor="bankName">
                                                                                                        {t("BANK_NAME")}
                                                                                                        <sup className="text-danger"> *</sup>
                                                                                                    </Label>
                                                                                                    <FormTextField
                                                                                                        name="bankName"
                                                                                                        id="bankName"
                                                                                                        placeholder={t("BANK_NAME")}
                                                                                                        disabled={isAccountActive ? false : true}
                                                                                                    />
                                                                                                </FormGroup>
                                                                                            </Col>

                                                                                            <Col md={6} xl={6}>
                                                                                                <FormGroup className="mb-3">
                                                                                                    <Label htmlFor="bankAccountNumber">
                                                                                                        {t("BANK_ACCOUNT_NUMBER")}
                                                                                                        <sup className="text-danger"> *</sup>
                                                                                                    </Label>
                                                                                                    <FormTextField
                                                                                                        type="text"
                                                                                                        name="bankAccountNumber"
                                                                                                        id="bankAccountNumber"
                                                                                                        placeholder={t("BANK_ACCOUNT_NUMBER")}
                                                                                                        disabled={isAccountActive ? false : true}
                                                                                                    />
                                                                                                </FormGroup>
                                                                                            </Col>

                                                                                            <Col md={6} xl={6}>
                                                                                                <FormGroup className="mb-3">
                                                                                                    <Label htmlFor="bankAccountName">
                                                                                                        {t("BANK_ACCOUNT_NAME")}
                                                                                                        <sup className="text-danger"> *</sup>
                                                                                                    </Label>
                                                                                                    <FormTextField
                                                                                                        name="bankAccountName"
                                                                                                        id="bankAccountName"
                                                                                                        placeholder={t("BANK_ACCOUNT_NAME")}
                                                                                                        disabled={isAccountActive ? false : true}
                                                                                                    />
                                                                                                </FormGroup>
                                                                                            </Col>

                                                                                            <Col md={12} xl={12}>
                                                                                                <FormGroup className="mb-3">
                                                                                                    <Label htmlFor="transferMethod">

                                                                                                        { values.bankCodeType == "" ? "" : values.bankCodeType.startsWith("swift") ?  <span style={{ color: "#cc3300" }}>{process.env.REACT_APP_DEDUCT_RATE_SWIFT} amount may deduct from each transaction for SWIFT transfer method.</span> 
                                                                                                        : <span style={{ color: "#cc3300" }}>{process.env.REACT_APP_DEDUCT_RATE_LOCAL} amount may deduct from each transaction for LOCAL transfer method.</span>}
                                                                                                    </Label>
                                                                                                </FormGroup>
                                                                                            </Col>

                                                                                            <Col md={12} xl={12} className="d-flex justify-content-end align-items-center gap-3 mt-3">
                                                                                                <Button disabled={isAccountActive ? false : true} className="btn-primary w-md" type="submit">{t("NEXT")}</Button>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Col>
                                                                                </Row>


                                                                            </CardBody>
                                                                        </Card>
                                                                    </TabPane>
                                                                </TabContent>
                                                                        </Form>
                                                                    )}
                                                                </Formik>

                                                                <Formik
                                                                    enableReinitialize
                                                                    initialValues={{
                                                                        //bankCountry: "",
                                                                        //accountCurrency: "",
                                                                       // bankCodeType: "",
                                                                       // swiftCode: "",
                                                                      //  bankName: "",
                                                                       // bankAccountNumber: "",
                                                                        //bankAccountName: "",
                                                                        businessName: "",
                                                                        nickName: "",
                                                                        email: "",
                                                                        // country:"",
                                                                        address: "",
                                                                        city: "",
                                                                        state: "",
                                                                        postalCode: "",
                                                                        legalRepFName: "",
                                                                        legalRepLName: "",
                                                                    }}
                                                                    validationSchema={validationSchemaRegisterBusinessPayee2}
                                                                    onSubmit={onSubmitRegisterBusinessPayee}
                                                                >
                                                                    {({isSubmitting, values,setFieldValue}) => (
                                                                        <Form>

                                                                <TabContent activeTab={activeTab} className="body">
                                                                    <TabPane tabId={2}>
                                                                        <Card className="p-0 shadow-none">
                                                                            <Row className='d-flex justify-content-start'>
                                                                                <Col md={10} xl={8}>
                                                                                    <CardTitle tag="h5">
                                                                                        {t("BUSINESS_DETAILS")}
                                                                                    </CardTitle>
                                                                                </Col>
                                                                            </Row>

                                                                            <CardBody className="px-0 py-1">
                                                                                <Row className='d-flex justify-content-start'>
                                                                                    <Col md={10} xl={8}>
                                                                                        <Row>

                                                                                            <Col md={6} xl={6}>
                                                                                                <FormGroup className="mb-3">
                                                                                                    <Label htmlFor="businessName">
                                                                                                        {t("BUSINESS_NAME")}
                                                                                                        <sup className="text-danger"> *</sup>
                                                                                                    </Label>
                                                                                                    <FormTextField
                                                                                                        name="businessName"
                                                                                                        id="businessName"
                                                                                                        placeholder={t("BUSINESS_NAME")}
                                                                                                        disabled={isAccountActive ? false : true}
                                                                                                    />
                                                                                                </FormGroup>
                                                                                            </Col>

                                                                                            <Col md={6} xl={6}>
                                                                                                <FormGroup className="mb-3">
                                                                                                    <Label htmlFor="nickName">
                                                                                                        {t("NICK_NAME")}
                                                                                                        <sup className="text-danger"> *</sup>
                                                                                                    </Label>
                                                                                                    <FormTextField
                                                                                                        name="nickName"
                                                                                                        id="nickName"
                                                                                                        placeholder={t("NICK_NAME")}
                                                                                                        disabled={isAccountActive ? false : true}
                                                                                                    />
                                                                                                </FormGroup>
                                                                                            </Col>

                                                                                            <Col md={6} xl={6}>
                                                                                                <FormGroup className="mb-3">
                                                                                                    <Label htmlFor="email">
                                                                                                        {t("EMAIL")}
                                                                                                        <sup className="text-danger"> *</sup>
                                                                                                    </Label>
                                                                                                    <FormTextField
                                                                                                        name="email"
                                                                                                        id="email"
                                                                                                        placeholder={t("EMAIL")}
                                                                                                        disabled={isAccountActive ? false : true}
                                                                                                    />
                                                                                                </FormGroup>
                                                                                            </Col>


                                                                                            <Col md={6} xl={6}>
                                                                                                <FormGroup className="mb-3">
                                                                                                    <Label htmlFor="address">
                                                                                                        {t("ADDRESS")}
                                                                                                        <sup className="text-danger"> *</sup>
                                                                                                    </Label>
                                                                                                    <FormTextField
                                                                                                        name="address"
                                                                                                        id="address"
                                                                                                        placeholder={t("ADDRESS")}
                                                                                                        disabled={isAccountActive ? false : true}
                                                                                                    />
                                                                                                </FormGroup>
                                                                                            </Col>

                                                                                            <Col md={6} xl={6}>
                                                                                                <FormGroup className="mb-3">
                                                                                                    <Label htmlFor="city">
                                                                                                        {t("CITY")}
                                                                                                        <sup className="text-danger"> *</sup>
                                                                                                    </Label>
                                                                                                    <FormTextField
                                                                                                        name="city"
                                                                                                        id="city"
                                                                                                        placeholder={t("CITY")}
                                                                                                        disabled={isAccountActive ? false : true}
                                                                                                    />
                                                                                                </FormGroup>
                                                                                            </Col>

                                                                                            <Col md={6} xl={6}>
                                                                                                <FormGroup className="mb-3">
                                                                                                    <Label htmlFor="state">
                                                                                                        {t("STATE")}
                                                                                                        <sup className="text-danger"> *</sup>
                                                                                                    </Label>
                                                                                                    <FormTextField
                                                                                                        name="state"
                                                                                                        id="state"
                                                                                                        placeholder={t("STATE")}
                                                                                                        disabled={isAccountActive ? false : true}
                                                                                                    />
                                                                                                </FormGroup>
                                                                                            </Col>

                                                                                            <Col md={6} xl={6}>
                                                                                                <FormGroup className="mb-3">
                                                                                                    <Label htmlFor="postalCode">
                                                                                                        {t("POSTAL_CODE")}
                                                                                                        <sup className="text-danger"> *</sup>
                                                                                                    </Label>
                                                                                                    <FormTextField
                                                                                                        name="postalCode"
                                                                                                        id="postalCode"
                                                                                                        placeholder={t("POSTAL_CODE")}
                                                                                                        disabled={isAccountActive ? false : true}
                                                                                                    />
                                                                                                </FormGroup>
                                                                                            </Col>

                                                                                            {
                                                                                                (isSubmitRegisterBusinessPayeeBankAccountDetails?.bankCountry && isSubmitRegisterBusinessPayeeBankAccountDetails?.bankCountry === "CN") ? (
                                                                                                        <>
                                                                                                            <Col md={6} xl={6}>
                                                                                                                <FormGroup className="mb-3">
                                                                                                                    <Label htmlFor="legalRepFName">
                                                                                                                        {t("FIRST_NAME_IN_CHINESE_LANGUAGE")}
                                                                                                                        <sup
                                                                                                                            className="text-danger"> *</sup>
                                                                                                                    </Label>
                                                                                                                    <FormTextField
                                                                                                                        name="legalRepFName"
                                                                                                                        id="legalRepFName"
                                                                                                                        placeholder={t("LEGAL_REPRESENTATIVE_FIRST_NAME_IN_CHINESE_LANGUAGE")}
                                                                                                                        disabled={isAccountActive ? false : true}
                                                                                                                    />
                                                                                                                </FormGroup>
                                                                                                            </Col>

                                                                                                            <Col md={6} xl={6}>
                                                                                                                <FormGroup className="mb-3">
                                                                                                                    <Label htmlFor="legalRepLName">
                                                                                                                        {t("LAST_NAME_IN_CHINESE_LANGUAGE")}
                                                                                                                        <sup
                                                                                                                            className="text-danger"> *</sup>
                                                                                                                    </Label>
                                                                                                                    <FormTextField
                                                                                                                        name="legalRepLName"
                                                                                                                        id="legalRepLName"
                                                                                                                        placeholder={t("LEGAL_REPRESENTATIVE_LAST_NAME_IN_CHINESE_LANGUAGE")}
                                                                                                                        disabled={isAccountActive ? false : true}
                                                                                                                    />
                                                                                                                </FormGroup>
                                                                                                            </Col>
                                                                                                        </>
                                                                                                    ) :
                                                                                                    (" ")
                                                                                            }

                                                                                            <Col md={12} xl={12}
                                                                                                 className="d-flex justify-content-end gap-3 mt-3">
                                                                                                <Button className="btn-secondary w-md"
                                                                                                        type="button" onClick={handleCancel1}>{t("BACK")}</Button>
                                                                                                <Button disabled={isAccountActive ? false : true} className="btn-primary w-md"
                                                                                                        type="submit">
                                                                                                    { loading ? (
                                                                                                        <Spinner size="sm">
                                                                                                            Loading...
                                                                                                        </Spinner>
                                                                                                    ) : "" }
                                                                                                    {' '}{' '}{t("SUBMIT")}</Button>
                                                                                            </Col>

                                                                                        </Row>
                                                                                    </Col>
                                                                                </Row>

                                                                            </CardBody>
                                                                        </Card>
                                                                    </TabPane>
                                                                </TabContent>
                                                            </Form>
                                                            )}
                                                        </Formik>
                                                            </div>

                                                        </div>


                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default RegisterPayee;

import { get, post } from "../helpers/api_helper"
import * as url from "./url_helper"



export const getCountries = () => get(url.GET_COUNTRIES)
export const getTitles = () => get(url.GET_TITLES)
export const getSecurityQuestion = () => get(url.GET_SECURITY_QUESTION)
export const getBankCode = () => get(url.GET_BANK_CODE)
export const getBankCurrency = () => get(url.GET_BANK_CURRENCY)
export const getCity = () => get(url.GET_CITY)
export const getIdType = () => get(url.GET_ID_TYPE)

export const airWallexUpdateKycState = (data) => post(url.AIRWALLEX_UPDAYE_KYC_STATUS, data)
export const getGlobalAccountBalance = (coUserId) => get(url.GET_GLOBAL_ACCOUNTS_BALANCE + coUserId)
export const getExpencesPercentage = (coUserId) => get(url.GET_EXPENCES_PERCENTAGE + coUserId)


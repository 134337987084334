import { get, post } from "../helpers/api_helper"
import * as url from "./url_helper"
import {GET_PAYEE_TRANSACTION_HISTORY_BY_ID} from "./url_helper";

// add Events

export const PayeeRegister = (data) => post(url.POST_PAYEE_REGISTER_DATA, data)
export const PayeeTransactionHistorySearch = (data) => post(url.POST_PAYEE_TRANSACTION_HISTORY, data)

export const UpdatePayeeRegister = (data) => post(url.PUT_PAYEE_REGISTER_DATA, data)

//get Event

export const getPayeeStatus = () => get(url.GET_PAYEE_STATUS)
export const getPayeePurpose = () => get(url.GET_PAYEE_PURPOSE)
export const getPayeeRegisterData = (data) => post(url.GET_PAYEE_REGISTER_DATA,data)
export const getPayeeTransactionHistory = (data) => post(url.GET_PAYEE_TRANSACTION_HISTORY,data)

export const getPayeeTransactionHistoryByPayeeId = (payeeId) => get(url.GET_PAYEE_TRANSACTION_HISTORY_BY_ID+payeeId)

export const getPayeeRegisterById = (data) => post(url.GET_REGISTER_PAYEE_BY_ID, data)

export const getPayeeDetailsBypayeeId = (data) => post(url.GET_PAYEE_BY_PAYEE_ID , data)

export const DeletePayeeRegister = (data) => post(url.DELETE_PAYEE_REGISTER_DATA, data)

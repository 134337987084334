import {useTranslation} from "react-i18next";
import {
    Badge,
    Button,
    Card, CardBody, CardTitle,
    Col,
    FormGroup,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row, Spinner
} from "reactstrap";
import React, {useEffect, useState} from "react";
import FormDropdown from "../../../common/formComponents/FormDropdown";
import {getBankCurrency, getCountries} from "../../../services/CommonService";
import {toast} from "react-toastify";
import {Form, Formik} from "formik";
import FormTextField from "../../../common/formComponents/FormTextField";
import * as yup from "yup";
import {UpdatePayeeRegister} from "../../../services/PayeeService";

const RegisterPayeeHistory = props =>{

    const {isOpen,toggleModal,rowData,fetchPayeeRegisterData} = props

    const [isModel, setModel] = useState(false);

    const [loading, setLoading] = useState(false);

    const [formFieldConfigsData, setFormFieldConfigsData] = useState([
        {
            country: "Singapore",
            code: "SG",
            currency: "SGD",
            payementMethod: "Local",
            localClearingSystem: "FAST",
            accountRoutingType1: null,
            accountRoutingValue1: null,
            swiftCode: "DBSSSGSG"
        },
        {
            country: "Singapore",
            code: "SG",
            currency: "SGD",
            payementMethod: "Local",
            localClearingSystem: "GIRO",
            accountRoutingType1: null,
            accountRoutingValue1: null,
            swiftCode: "DBSSSGSG"
        },
        {
            country: "Singapore",
            code: "SG",
            currency: "SGD",
            payementMethod: "Local",
            localClearingSystem: "RTGS",
            accountRoutingType1: null,
            accountRoutingValue1: null,
            swiftCode: "DBSSSGSG"
        },




        {
            country: "Hong Kong",
            code: "HK",
            currency: "HKD",
            payementMethod: "Local",
            localClearingSystem: "ACH",
            accountRoutingType1: "bank_code",
            accountRoutingValue1: "024"
        },

        {
            country: "Hong Kong",
            code: "HK",
            currenc: "HKD",
            payementMethod: "Local",
            localClearingSystem: "FPS",
            accountRoutingType1: "bank_code",
            accountRoutingValue1: "024"
        },
        {
            country: "Hong Kong",
            code: "HK",
            currency: "HKD",
            payementMethod: "Local",
            localClearingSystem: "RTGS",
            accountRoutingType1: null,
            accountRoutingValue1: null,
            swiftCode: "DBSSSGSG"
        },



        {
            country: "China",
            code: "CN",
            currency: "CNY",
            payementMethod: "Local",
            localClearingSystem: null,
            accountRoutingType1: "cnaps",
            accountRoutingValue1: "105584000005"
        },



        {
            country: "United Kingdom",
            code: "GB",
            currency: "GBP",
            payementMethod: "Local",
            localClearingSystem: "FASTER_PAYMENTS",
            accountRoutingType1: "sort_code",
            accountRoutingValue1: "609242"
        },





        {
            country: "United Arab Emirates",
            code: "AE",
            currency: "AED",
            payementMethod: "Local",
            localClearingSystem: null,
            accountRoutingType1: null,
            accountRoutingValue1: null,
            iban: "AE070331234567890123456"
        },



        {
            country: "United State",
            code: "US",
            currency: "USD",
            payementMethod: "Local",
            localClearingSystem: "ACH",
            accountRoutingType1: "aba",
            accountRoutingValue1: "021000021"
        },
        {
            country: "United State",
            code: "US",
            currency: "USD",
            payementMethod: "Local",
            localClearingSystem: "FEDWIRE",
            accountRoutingType1: "aba",
            accountRoutingValue1: "021000021"
        },
        {
            country: "United State",
            code: "US",
            currency: "USD",
            payementMethod: "Local",
            localClearingSystem: "PAPER_CHECK",
            accountRoutingType1: null,
            accountRoutingValue1: null
        },



        {
            country: "Australia",
            code: "AU",
            currency: "AUD",
            payementMethod: "Local",
            localClearingSystem: "BANK_TRANSFER",
            accountRoutingType1: "bsb",
            accountRoutingValue1: "082401"
        },
        {
            country: "Australia",
            code: "AU",
            currency: "AUD",
            payementMethod: "Local",
            localClearingSystem: "BPAY",
            accountRoutingType1: "bpay_biller_code",
            accountRoutingValue1: "123000"
        },



        {
            country: "Japan",
            code: "JP",
            currency: "JPY",
            payementMethod: "Local",
            localClearingSystem: "",
            accountRoutingType1: "bank_code",
            accountRoutingValue1: "0001",
            accountRoutingType2: "branch_code",
            accountRoutingValue2: "001"
        },



        {
            country: "Canada",
            code: "CA",
            currency: "CAD",
            payementMethod: "Local",
            localClearingSystem: "",
            accountRoutingType1: "institution_number",
            accountRoutingValue1: "010",
            accountRoutingType2: "transit_number",
            accountRoutingValue2: "04832"
        },

        {
            country: "India",
            code: "IN",
            currency: "INR",
            payementMethod: "Local",
            localClearingSystem: null,
            accountRoutingType1: "bank_code",
            accountRoutingValue1: "ICIC0001962"
        }
    ])

    useEffect(()=>{
        if (rowData) {

            if (rowData.payee_type === "PERSONAL"){
                setModel(true)
            }
        }

    },[props.success,rowData])


    const { t } = useTranslation()



    const [isBankCurrency, setBankCurrency] = useState([])

    const [countries, setCountries] = useState([]);

    const [transferMethods, setTransferMethods] = useState([]);

    useEffect(()=>{
        let gg= rowData?.payment_methods[0] === "LOCAL" && (rowData.bank_country === "SG" || rowData.bank_country === "HK")  ? rowData.swift_code : null
        fetchCountries()
        fetchBankCurrency()
    },[])

    useEffect(()=>{
        if(countries.length > 0){
            const selectedCountry = countries.find(
                (country) => country.country_code == rowData.bank_country
            );
            const transferOfMethodsData = selectedCountry?.source_currency.map(
                (method) => {
                    return {
                        value: method.toUpperCase(),
                        label: method.toUpperCase(),
                    };
                }
            );

            setTransferMethods(transferOfMethodsData);
        }
    },[countries])

    const fetchCountries = async () => {

        try {

            const response = await getCountries();
            const data = response.result; // Assuming your response contains the data array

            // Transform data for setCountries
            const countriesData = data.map((country) => ({
                ...country,
                label: country.country_name,
                value: country.country_code, // Assuming you want the value in lowercase
            }));

            setCountries(countriesData);
        } catch (error) {
            toast.error(t("ERROR_FETCH_COUNTRIES"));
        }
    };

    const fetchBankCurrency = async () =>{

        try {
            const response = await getBankCurrency()

            let bankCurrency = response.result.map(bankCurrency => ({
                label: bankCurrency.bank_currency,
                value: bankCurrency.bank_currency_code,
            }));

            setBankCurrency(bankCurrency)

        }catch (error){
            toast.error(t("ERROR_FETCH_BANK_CURRENCY"))
        }

    }

    const changeCountry = (value, form) => {

        const selectedCountry = countries.find(
            (country) => country.country_code == value
        );

        const transferOfMethodsData = selectedCountry.source_currency.map(
            (method) => {
                return {
                    value: method.toUpperCase(),
                    label: method.toUpperCase(),
                };
            }
        );
        setTransferMethods(transferOfMethodsData);
        form.setFieldValue("bankCountry", value);
        // form.setFieldValue("swiftCode", "");
        // form.setFieldValue("localSwiftCode", "");
        // form.setFieldValue("accountRoutingValue", "");
    };

    // const changeTransferMethods =  (value, form) => {
    //     form.setFieldValue("bankCodeType", value);
    //     form.setFieldValue("swiftCode", "");
    //     form.setFieldValue("localSwiftCode", "");
    //     form.setFieldValue("accountRoutingValue", "");
    // };

    const onSubmitRegisterIndividualPayee = async (values, { resetForm }) => {

        setLoading(true)

        let swiftCode = (values.bankCodeType.toUpperCase() === "SWIFT") ? values.swiftCode : ""

        let localClearVal = ""
        let localClearVal1 = ""

        if (values && values.bankCodeType) {
            localClearVal = values.bankCodeType.split("-")[0].replace(/\s/g, '').toLowerCase().length > 0 ? values.bankCodeType.split("-")[0].replace(/\s/g, '').toLowerCase() : "";
        }

        if (values && values.bankCodeType) {
            localClearVal1 = values?.bankCodeType?.split("-")[1]?.replace(/\s/g, '').toLowerCase().length > 0 ? values?.bankCodeType?.split("-")[1]?.replace(/\s/g, '').toLowerCase() : "";
        }

        let configData = formFieldConfigsData.find(obj =>
            obj?.code?.toLowerCase() === values.bankCountry.toLowerCase() &&
            obj?.payementMethod?.toLowerCase() === localClearVal &&
            obj?.localClearingSystem?.toLowerCase() === localClearVal1

        );

        let dataRegisterIndividualPayee={
            payee_id: rowData.payee_id,
            co_user_id: rowData.co_user_id,
            payee_type: "PERSONAL",
            first_name: values.firstName,
            last_name: values.lastName,
            bank_code_type: values.bankCountry.toUpperCase(),
            bank_code: "",
            bank_name: values.bankName,
            branch_name: "",
            account_number: values.bankAccountNumber,
            account_name: values.bankAccountName,
            bank_country: values.bankCountry.toUpperCase(),
            bank_currency: values.accountCurrency.toUpperCase(),
            nick_name: values.nickName,
            email: values.email,
            id_issued_in: "",
            id_type: "",
            id_number: "",
            id_image: "",
            br_number: "",
            legal_rep_last_name: "",
            legal_rep_first_name: "",
            cnpas: "",
            is_active: true,
            is_verified: true,
            date_of_birth: null,
            swift_code: values.swiftCode ? values.swiftCode : values.localSwiftCode ? values.localSwiftCode : null,
            created_at: "",
            verified_at: "",
            addressList: [
            {
                address_id: rowData.addressList[0].address_id,
                payee_id: rowData.payee_id,
                address_type: "PERSONAL",
                address_line1: values.address,
                address_line2: "",
                address_line3: "",
                city_province: values.city,
                street: "my streat",
                postal_code: values.postalCode,
                state: values.state
            }
        ],
            beneficiary_id: rowData.beneficiary_id,
            account_routing_type1: configData?.accountRoutingType1 ? configData.accountRoutingType1 : null,
            account_routing_value1: values.accountRoutingValue ? values.accountRoutingValue : null,
            local_clearing_system: configData?.localClearingSystem ? configData.localClearingSystem.toUpperCase() : null,

            payment_methods: [
                values.bankCodeType.split("-")[0].replace(/\s/g, '').toUpperCase()
        ],
            company_name: "",
            error_code: null
        }

        try {
            const response = await UpdatePayeeRegister(dataRegisterIndividualPayee)
            setLoading(false)
            toast.success(t("UPDATE_INDIVIDUAL_PAYEE_SUCCESS"))
            toggleModal()
           // resetForm()
            fetchPayeeRegisterData()
        } catch (error) {
            setLoading(false)
            if (error.code === "ERR_NETWORK"){
                toast.error(`Error: ${error.message}`)
            }else {
                toast.error(`Error: ${error.response.data.result.error}`)
            }
        }
    }



    const onSubmitRegisterBusinessPayee = async (values, { resetForm }) => {

        setLoading(true)
        let swiftCode = (values.bankCodeType.toUpperCase() === "SWIFT") ? values.swiftCode : ""
        let legalRepLName = (values.bankCountry.toUpperCase() === "CN") ? values.legalRepLName : ""
        let legalRepFName = (values.bankCountry.toUpperCase() === "CN") ? values.legalRepFName : ""

        let localClearVal = ""
        let localClearVal1 = ""

        if (values && values.bankCodeType) {
            localClearVal = values.bankCodeType.split("-")[0].replace(/\s/g, '').toLowerCase().length > 0 ? values.bankCodeType.split("-")[0].replace(/\s/g, '').toLowerCase() : "";
        }

        if (values && values.bankCodeType) {
            localClearVal1 = values?.bankCodeType?.split("-")[1]?.replace(/\s/g, '').toLowerCase().length > 0 ? values?.bankCodeType?.split("-")[1]?.replace(/\s/g, '').toLowerCase() : "";
        }

        let configData = formFieldConfigsData.find(obj =>
            obj?.code?.toLowerCase() === values.bankCountry.toLowerCase() &&
            obj?.payementMethod?.toLowerCase() === localClearVal &&
            obj?.localClearingSystem?.toLowerCase() === localClearVal1

        );

        let dataRegisterBusinessPayee={
            payee_id: rowData.payee_id,
            co_user_id: rowData.co_user_id,
            payee_type: "COMPANY",
            first_name: values.firstName,
            last_name: values.lastName,
            bank_code_type: values.bankCountry.toUpperCase(),
            bank_code: "",
            bank_name: values.bankName,
            branch_name: "",
            account_number: values.bankAccountNumber,
            account_name: values.bankAccountName,
            bank_country: values.bankCountry.toUpperCase(),
            bank_currency: values.accountCurrency.toUpperCase(),
            nick_name: values.nickName,
            email: values.email,
            id_issued_in: "",
            id_type: "",
            id_number: "",
            id_image: "",
            br_number: "",
            legal_rep_last_name: legalRepLName,
            legal_rep_first_name: legalRepFName,
            cnpas: "",
            is_active: true,
            is_verified: true,
            date_of_birth: null,
            swift_code: values.swiftCode ? values.swiftCode : values.localSwiftCode ? values.localSwiftCode : null,
            created_at: "",
            verified_at: "",
            addressList: [
                {
                    address_id: rowData.addressList[0].address_id,
                    payee_id: rowData.payee_id,
                    address_type: "COMPANY",
                    address_line1: values.address,
                    address_line2: "",
                    address_line3: "",
                    city_province: values.city,
                    street: "my streat",
                    postal_code: values.postalCode,
                    state: values.state
                }
            ],
            beneficiary_id: rowData.beneficiary_id,
            account_routing_type1: configData?.accountRoutingType1 ? configData.accountRoutingType1 : null,
            account_routing_value1: values.accountRoutingValue ? values.accountRoutingValue : null,
            local_clearing_system: configData?.localClearingSystem ? configData.localClearingSystem.toUpperCase() : null,
            payment_methods: [
                values.bankCodeType.split("-")[0].replace(/\s/g, '').toUpperCase()
            ],
            company_name: values.businessName,
            error_code: null
        }

        try {
            const response = await UpdatePayeeRegister(dataRegisterBusinessPayee)
            setLoading(false)
            toast.success(t("UPDATE_BUSINESS_PAYEE_SUCCESS"))
            toggleModal()
            //resetForm()
            fetchPayeeRegisterData()
        } catch (error) {
            setLoading(false)
            if (error.code === "ERR_NETWORK"){
                toast.error(`Error: ${error.message}`)
            }else {
                toast.error(`Error: ${error.response.data.result.error}`)
            }
        }
    }

    // Edit Payee
    const validationSchema = yup.object().shape({
        bankCountry: yup.string().required(t("BANK_COUNTRY_IS_REQUIRED"))
            .notOneOf([null, t("SELECT_AN_OPTION")], t("PLEASE_SELECT_A_VALID_COUNTRY"))
        ,
        accountCurrency: yup.string().required(t("ACCOUNT_CURRENCY_IS_REQUIRED"))
            .notOneOf([null, t("SELECT_AN_OPTION")], t("PLEASE_SELECT_A_VALID_ACCOUNT_CURRENCY"))
        ,
        bankCodeType: yup.string().required(t("TRANSFER_METHOD_IS_REQUIRED"))
            .notOneOf([null, t("SELECT_AN_OPTION")], t("PLEASE_SELECT_A_VALID_TRANSFER_METHOD"))
        ,
        swiftCode: yup.string().when("bankCodeType", {
            is: (bankCodeType) => bankCodeType === "SWIFT",
            then: yup.string().required(t("SWIFT_CODE_IS_REQUIRED"))
        }),
        localSwiftCode: yup.string().when(["bankCodeType", "bankCountry"], {
            is: (bankCodeType, bankCountry) =>(bankCodeType === "LOCAL - FAST" || bankCodeType === "LOCAL - GIRO" || bankCodeType === "LOCAL - RTGS"),
            then: yup.string().required(t("SWIFT_CODE_IS_REQUIRED"))
        }),
        accountRoutingValue: yup.string().when(["bankCodeType", "bankCountry"], {
            is: (bankCodeType, bankCountry) => bankCodeType === "LOCAL - ACH" ||
                bankCodeType === "LOCAL - FPS" ||
                (bankCodeType === "LOCAL" && bankCountry === "CN") ||
                bankCodeType === "LOCAL - FASTER_PAYMENTS" ||
                // bankCodeType === "local - paper_check" ||
                bankCodeType === "LOCAL - FEDWIRE" ||
                bankCodeType === "LOCAL - BPAY" ||
                bankCodeType === "LOCAL - BANK_TRANSFER" ||
                (bankCodeType === "LOCAL" && bankCountry === "JP") ||
                (bankCodeType === "LOCAL" && bankCountry === "CA") ||
                (bankCodeType === "LOCAL" && bankCountry === "IN")
            ,
            then: yup.string().required(t("ACCOUNT_ROUTING_VALUE_IS_REQUIRED"))
        }),
        bankAccountNumber: yup.string()
            .required(t("BANK_ACCOUNT_NUMBER_IS_REQUIRED"))
            .min(8, t("BANK_ACCOUNT_NUMBER_MIN_LENGTH"))
            .matches(/^\d+$/, t("BANK_ACCOUNT_NUMBER_MUST_BE_NUMERIC")),
        bankAccountName: yup.string().required(t("BANK_ACCOUNT_NAME_IS_REQUIRED")),
        firstName: yup.string().required(t("FIRST_NAME_IS_REQUIRED")),
        bankName: yup.string().required(t("BANK_NAME_IS_REQUIRED")),
        lastName: yup.string().required(t("LAST_NAME_IS_REQUIRED")),
        nickName: yup.string().required(t("NICK_NAME_IS_REQUIRED")),
        email: yup.string().required(t("EMAIL_IS_REQUIRED")),
        address: yup.string().required(t("ADDRESS_IS_REQUIRED")),
        postalCode: yup.string().required(t("POSTAL_CODE_IS_REQUIRED")),
        city: yup.string().required(t("CITY_IS_REQUIRED")),
        state: yup.string().required(t("STATE_IS_REQUIRED")),
    })

    // Register Business Payee
    const validationSchemaRegisterBusinessPayee = yup.object().shape({
        bankCountry: yup.string().required(t("BANK_COUNTRY_IS_REQUIRED"))
            .notOneOf([null, t("SELECT_AN_OPTION")], t("PLEASE_SELECT_A_VALID_COUNTRY"))
        ,
        accountCurrency: yup.string().required(t("ACCOUNT_CURRENCY_IS_REQUIRED"))
            .notOneOf([null, t("SELECT_AN_OPTION")], t("PLEASE_SELECT_A_VALID_ACCOUNT_CURRENCY"))
        ,
        bankCodeType: yup.string().required(t("TRANSFER_METHOD_IS_REQUIRED"))
            .notOneOf([null, t("SELECT_AN_OPTION")], t("PLEASE_SELECT_A_VALID_TRANSFER_METHOD"))
        ,
        swiftCode: yup.string().when("bankCodeType", {
            is: (bankCodeType) => bankCodeType === "SWIFT",
            then: yup.string().required(t("SWIFT_CODE_IS_REQUIRED"))
        }),
        localSwiftCode: yup.string().when(["bankCodeType", "bankCountry"], {
            is: (bankCodeType, bankCountry) =>(bankCodeType === "LOCAL - FAST" || bankCodeType === "LOCAL - GIRO" || bankCodeType === "LOCAL - RTGS"),
            then: yup.string().required(t("SWIFT_CODE_IS_REQUIRED"))
        }),
        accountRoutingValue: yup.string().when(["bankCodeType", "bankCountry"], {
            is: (bankCodeType, bankCountry) => bankCodeType === "LOCAL - ACH" ||
                bankCodeType === "LOCAL - FPS" ||
                (bankCodeType === "LOCAL" && bankCountry === "CN") ||
                bankCodeType === "LOCAL - FASTER_PAYMENTS" ||
                // bankCodeType === "local - paper_check" ||
                bankCodeType === "LOCAL - FEDWIRE" ||
                bankCodeType === "LOCAL - BPAY" ||
                bankCodeType === "LOCAL - BANK_TRANSFER" ||
                (bankCodeType === "LOCAL" && bankCountry === "JP") ||
                (bankCodeType === "LOCAL" && bankCountry === "CA") ||
                (bankCodeType === "LOCAL" && bankCountry === "IN")
            ,
            then: yup.string().required(t("ACCOUNT_ROUTING_VALUE_IS_REQUIRED"))
        }),
        bankAccountNumber: yup.string()
            .required(t("BANK_ACCOUNT_NUMBER_IS_REQUIRED"))
            .min(8, t("BANK_ACCOUNT_NUMBER_MIN_LENGTH"))
            .matches(/^\d+$/, t("BANK_ACCOUNT_NUMBER_MUST_BE_NUMERIC")),
        bankAccountName: yup.string().required(t("BANK_ACCOUNT_NAME_IS_REQUIRED")),
        businessName: yup.string().required(t("BUSINESS_NAME_IS_REQUIRED")),
        bankName: yup.string().required(t("BANK_NAME_IS_REQUIRED")),
        nickName: yup.string().required(t("NICK_NAME_IS_REQUIRED")),
        email: yup.string().required(t("EMAIL_IS_REQUIRED")),
        address: yup.string().required(t("ADDRESS_IS_REQUIRED")),
        postalCode: yup.string().required(t("POSTAL_CODE_IS_REQUIRED")),
        city: yup.string().required(t("CITY_IS_REQUIRED")),
        state: yup.string().required(t("STATE_IS_REQUIRED")),
        legalRepFName:yup.string().when("bankCountry", {
            is: (bankCountry) => bankCountry === "CN",
            then: yup.string().required(t("LEGAL_REPRESENTATIVE_FIRST_NAME_IN_CHINESE_LANGUAGE"))
        }),
        legalRepLName:yup.string().when("bankCountry", {
            is: (bankCountry) => bankCountry === "CN",
            then: yup.string().required(t("LEGAL_REPRESENTATIVE_LAST_NAME_IN_CHINESE_LANGUAGE"))
        }),
    })

    document.title = t("PAYEE_TPS_SOLUTIONS");

    return (
        <div>
            <Modal isOpen={isOpen} toggle={toggleModal} centered={true} size="xl">
                <ModalHeader toggle={null}>{isModel ? t("UPDATE_REGISTER_INDIVIDUAL_PAYEE") : t("UPDATE_REGISTER_BUSINESS_PAYEE")}</ModalHeader>
                <ModalBody>

                    {
                        isModel ?

                            (
                                <Formik
                                    enableReinitialize
                                    initialValues={{
                                        bankCountry:rowData ? rowData.bank_country : "",
                                        accountCurrency:rowData ? rowData.bank_currency : "",
                                        bankCodeType:rowData?.payment_methods[0] === "SWIFT" ? "SWIFT" : (rowData?.payment_methods[0] === "LOCAL" && rowData.local_clearing_system < 0 ) ? "LOCAL" : rowData.local_clearing_system ? `${rowData?.payment_methods[0]} - ${rowData?.local_clearing_system}` : "",
                                        swiftCode: rowData?.swift_code ? rowData?.swift_code : "",
                                        bankName:rowData ? rowData.bank_name : "",
                                        bankAccountNumber:rowData ? rowData.accountNumber : "",
                                        bankAccountName:rowData ? rowData.account_name : "",
                                        firstName:rowData ? rowData.first_name : "",
                                        lastName:rowData ? rowData.last_name : "",
                                        nickName:rowData ? rowData.nickname : "",
                                        email:rowData ? rowData.email : "",
                                        // country:"",
                                        address: rowData ? rowData.addressList[0].address_line1 : "",
                                        city:rowData ? rowData.addressList[0].city_province : "",
                                        state:rowData ? rowData.addressList[0].state : "",
                                        postalCode:rowData ? rowData.addressList[0].postal_code : "",
                                        localSwiftCode:(rowData?.payment_methods[0] === "LOCAL" && (rowData.bank_country === "SG" || (rowData.bank_country === "HK" && rowData.local_clearing_system === "RTGS")) ) ? rowData.swift_code : "",
                                        accountRoutingValue:rowData.account_routing_value1 ? rowData.account_routing_value1 : "",
                                    }}
                                    validationSchema={validationSchema}
                                    onSubmit={onSubmitRegisterIndividualPayee}
                                >
                                    {({ isSubmitting,values ,setFieldValue }) => (
                                        <Form>

                                                <Card

                                                >
                                                    <CardTitle tag="h5">
                                                        {t("BANK_ACCOUNT_DETAILS")}
                                                    </CardTitle>
                                                    <CardBody>
                                                        <Row>
                                                            <Col md={6} xl={4}>
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="status">{t("PAYEE_TYPE")}</Label>
                                                                    <br/>
                                                                    {<Badge className={"font-size-14 p-2 bg-warning"}>{rowData.payeeType}</Badge>}
                                                                </FormGroup>
                                                            </Col>

                                                            <Col md={6} xl={4}>
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="bankCountry">
                                                                        {t("BANK_COUNTRY")}
                                                                        <sup className="text-danger"> *</sup>
                                                                    </Label>
                                                                    <FormDropdown
                                                                        name="bankCountry"
                                                                        id="bankCountry"
                                                                        optionsValues={countries}
                                                                        changeDropdown={changeCountry}
                                                                        setFieldValue={setFieldValue}
                                                                    />
                                                                </FormGroup>
                                                            </Col>

                                                            <Col md={6} xl={4}>
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="bankCodeType">
                                                                        {t("TRANSFER_METHOD")}
                                                                        <sup className="text-danger"> *</sup>
                                                                    </Label>
                                                                    <FormDropdown
                                                                        name="bankCodeType"
                                                                        id="bankCodeType"
                                                                        optionsValues={transferMethods}
                                                                        //changeDropdown={changeTransferMethods}
                                                                        //setFieldValue={setFieldValue}
                                                                    />
                                                                </FormGroup>
                                                            </Col>

                                                            {
                                                                (values?.bankCodeType && values?.bankCodeType === "SWIFT") ? (
                                                                        <Col md={6} xl={4}>
                                                                            <FormGroup className="mb-3">
                                                                                <Label htmlFor="swiftCode">
                                                                                    {t("SWIFT_CODE")}
                                                                                    <sup className="text-danger"> *</sup>
                                                                                </Label>
                                                                                <FormTextField
                                                                                    name="swiftCode"
                                                                                    id="swiftCode"
                                                                                    placeholder={t("SWIFT_CODE")}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>

                                                                    ) :
                                                                    null
                                                            }

                                                            {/* -------------------- Singapore ---------------------------------------------- */}

                                                            {
                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (
                                                                    (values?.bankCountry === "SG" && values?.bankCodeType?.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "LOCAL" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "FAST") ?(
                                                                        <Col md={6} xl={4}>
                                                                            <FormGroup className="mb-3">
                                                                                <Label htmlFor="localSwiftCode">
                                                                                    {t("SWIFT_CODE")}
                                                                                    <sup
                                                                                        className="text-danger"> *</sup>
                                                                                </Label>
                                                                                <FormTextField
                                                                                    name="localSwiftCode"
                                                                                    id="localSwiftCode"
                                                                                    placeholder={t("SWIFT_CODE")}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    ) : null
                                                                ): null
                                                            }

                                                            {
                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (
                                                                        (values?.bankCountry === "SG" && values?.bankCodeType?.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "LOCAL" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "GIRO") ? (
                                                                            <Col md={6} xl={4}>
                                                                                <FormGroup className="mb-3">
                                                                                    <Label htmlFor="localSwiftCode">
                                                                                        {t("SWIFT_CODE")}
                                                                                        <sup
                                                                                            className="text-danger"> *</sup>
                                                                                    </Label>
                                                                                    <FormTextField
                                                                                        name="localSwiftCode"
                                                                                        id="localSwiftCode"
                                                                                        placeholder={t("SWIFT_CODE")}

                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        ) : null
                                                                    ):
                                                                    null
                                                            }

                                                            {
                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (
                                                                        (values?.bankCountry === "SG" && values?.bankCodeType?.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "LOCAL" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "RTGS") ? (
                                                                            <Col md={6} xl={4}>
                                                                                <FormGroup className="mb-3">
                                                                                    <Label htmlFor="localSwiftCode">
                                                                                        {t("SWIFT_CODE")}
                                                                                        <sup
                                                                                            className="text-danger"> *</sup>
                                                                                    </Label>
                                                                                    <FormTextField
                                                                                        name="localSwiftCode"
                                                                                        id="localSwiftCode"
                                                                                        placeholder={t("SWIFT_CODE")}

                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        ) : null

                                                                    ):
                                                                    null
                                                            }

                                                            {/* --------------------  Hong Kong ---------------------------------------------- */}

                                                            {
                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (
                                                                        (values?.bankCountry === "HK" && values?.bankCodeType.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "LOCAL" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "ACH") ? (
                                                                            <Col md={6} xl={4}>
                                                                                <FormGroup className="mb-3">
                                                                                    <Label htmlFor="accountRoutingValue">
                                                                                        {t("ACCOUNT_ROUTING_VALUE")}
                                                                                        <sup
                                                                                            className="text-danger"> *</sup>
                                                                                    </Label>
                                                                                    <FormTextField
                                                                                        name="accountRoutingValue"
                                                                                        id="accountRoutingValue"
                                                                                        placeholder={t("ACCOUNT_ROUTING_VALUE")}

                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        ) : null

                                                                    ):
                                                                    null
                                                            }

                                                            {
                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (
                                                                        (values?.bankCountry === "HK" && values?.bankCodeType?.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "LOCAL" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "FPS") ? (
                                                                            <Col md={6} xl={4}>
                                                                                <FormGroup className="mb-3">
                                                                                    <Label htmlFor="accountRoutingValue">
                                                                                        {t("ACCOUNT_ROUTING_VALUE")}
                                                                                        <sup
                                                                                            className="text-danger"> *</sup>
                                                                                    </Label>
                                                                                    <FormTextField
                                                                                        name="accountRoutingValue"
                                                                                        id="accountRoutingValue"
                                                                                        placeholder={t("ACCOUNT_ROUTING_VALUE")}

                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        ) : null

                                                                    ):
                                                                    null
                                                            }

                                                            {
                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (
                                                                        (values?.bankCountry === "HK" && values?.bankCodeType?.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "LOCAL" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "RTGS") ? (
                                                                            <Col md={6} xl={4}>
                                                                                <FormGroup className="mb-3">
                                                                                    <Label htmlFor="localSwiftCode">
                                                                                        {t("SWIFT_CODE")}
                                                                                        <sup
                                                                                            className="text-danger"> *</sup>
                                                                                    </Label>
                                                                                    <FormTextField
                                                                                        name="localSwiftCode"
                                                                                        id="localSwiftCode"
                                                                                        placeholder={t("SWIFT_CODE")}

                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        ) : null

                                                                    ):
                                                                    null
                                                            }

                                                            {/* --------------------  China ---------------------------------------------- */}

                                                            {
                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string') ? (
                                                                        (values?.bankCountry === "CN" && values?.bankCodeType.length > 0 && values?.bankCodeType === "LOCAL") ?(
                                                                            <Col md={6} xl={4}>
                                                                                <FormGroup className="mb-3">
                                                                                    <Label htmlFor="accountRoutingValue">
                                                                                        {t("ACCOUNT_ROUTING_VALUE")}
                                                                                        <sup
                                                                                            className="text-danger"> *</sup>
                                                                                    </Label>
                                                                                    <FormTextField
                                                                                        name="accountRoutingValue"
                                                                                        id="accountRoutingValue"
                                                                                        placeholder={t("ACCOUNT_ROUTING_VALUE")}

                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        ) : null

                                                                    ):
                                                                    null
                                                            }

                                                            {/* --------------------  United Kingdom ---------------------------------------------- */}

                                                            {
                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (
                                                                        (values?.bankCountry === "GB" && values?.bankCodeType.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "LOCAL" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "FASTER_PAYMENTS") ? (
                                                                            <Col md={6} xl={4}>
                                                                                <FormGroup className="mb-3">
                                                                                    <Label htmlFor="accountRoutingValue">
                                                                                        {t("ACCOUNT_ROUTING_VALUE")}
                                                                                        <sup
                                                                                            className="text-danger"> *</sup>
                                                                                    </Label>
                                                                                    <FormTextField
                                                                                        name="accountRoutingValue"
                                                                                        id="accountRoutingValue"
                                                                                        placeholder={t("ACCOUNT_ROUTING_VALUE")}

                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        ) : null

                                                                    ):
                                                                    null
                                                            }

                                                            {/* --------------------  United State ---------------------------------------------- */}

                                                            {
                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (
                                                                        (values?.bankCountry === "US" && values?.bankCodeType?.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "LOCAL" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "ACH") ? (
                                                                            <Col md={6} xl={4}>
                                                                                <FormGroup className="mb-3">
                                                                                    <Label htmlFor="accountRoutingValue">
                                                                                        {t("ACCOUNT_ROUTING_VALUE")}
                                                                                        <sup
                                                                                            className="text-danger"> *</sup>
                                                                                    </Label>
                                                                                    <FormTextField
                                                                                        name="accountRoutingValue"
                                                                                        id="accountRoutingValue"
                                                                                        placeholder={t("ACCOUNT_ROUTING_VALUE")}

                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        ) : null

                                                                    ):
                                                                    null
                                                            }

                                                            {
                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (
                                                                        (values?.bankCountry === "US" && values?.bankCodeType?.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "LOCAL" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "FEDWIRE") ? (
                                                                            <Col md={6} xl={4}>
                                                                                <FormGroup className="mb-3">
                                                                                    <Label htmlFor="accountRoutingValue">
                                                                                        {t("ACCOUNT_ROUTING_VALUE")}
                                                                                        <sup
                                                                                            className="text-danger"> *</sup>
                                                                                    </Label>
                                                                                    <FormTextField
                                                                                        name="accountRoutingValue"
                                                                                        id="accountRoutingValue"
                                                                                        placeholder={t("ACCOUNT_ROUTING_VALUE")}

                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        ) : null

                                                                    ):
                                                                    null
                                                            }

                                                            {/*{*/}
                                                            {/*    (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (*/}
                                                            {/*        (values?.bankCountry === "US" && values?.bankCodeType?.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "local" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "paper_check") ? (*/}
                                                            {/*            <Col md={6} xl={6}>*/}
                                                            {/*                <FormGroup className="mb-3">*/}
                                                            {/*                    <Label htmlFor="accountRoutingValue">*/}
                                                            {/*                        {t("ACCOUNT_ROUTING_VALUE")}*/}
                                                            {/*                        <sup*/}
                                                            {/*                            className="text-danger"> *</sup>*/}
                                                            {/*                    </Label>*/}
                                                            {/*                    <FormTextField*/}
                                                            {/*                        name="accountRoutingValue"*/}
                                                            {/*                        id="accountRoutingValue"*/}
                                                            {/*                        placeholder={t("ACCOUNT_ROUTING_VALUE")}*/}
                                                            {/*                        disabled={isAccountActive ? false : true}*/}
                                                            {/*                    />*/}
                                                            {/*                </FormGroup>*/}
                                                            {/*            </Col>*/}
                                                            {/*            ) : null*/}

                                                            {/*        ):*/}
                                                            {/*        null*/}
                                                            {/*}*/}

                                                            {/* --------------------  Australia ---------------------------------------------- */}

                                                            {
                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (
                                                                        (values?.bankCountry === "AU" && values?.bankCodeType?.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "LOCAL" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "BPAY") ? (
                                                                            <Col md={6} xl={4}>
                                                                                <FormGroup className="mb-3">
                                                                                    <Label htmlFor="accountRoutingValue">
                                                                                        {t("ACCOUNT_ROUTING_VALUE")}
                                                                                        <sup
                                                                                            className="text-danger"> *</sup>
                                                                                    </Label>
                                                                                    <FormTextField
                                                                                        name="accountRoutingValue"
                                                                                        id="accountRoutingValue"
                                                                                        placeholder={t("ACCOUNT_ROUTING_VALUE")}

                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        ) : null

                                                                    ):
                                                                    null
                                                            }

                                                            {
                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (
                                                                        (values?.bankCountry === "AU" && values.bankCodeType.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "LOCAL" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "BANK_TRANSFER") ? (
                                                                            <Col md={6} xl={4}>
                                                                                <FormGroup className="mb-3">
                                                                                    <Label htmlFor="accountRoutingValue">
                                                                                        {t("ACCOUNT_ROUTING_VALUE")}
                                                                                        <sup
                                                                                            className="text-danger"> *</sup>
                                                                                    </Label>
                                                                                    <FormTextField
                                                                                        name="accountRoutingValue"
                                                                                        id="accountRoutingValue"
                                                                                        placeholder={t("ACCOUNT_ROUTING_VALUE")}

                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        ) : null

                                                                    ):
                                                                    null
                                                            }

                                                            {/* --------------------  Japan ---------------------------------------------- */}

                                                            {
                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string') ? (
                                                                        (values?.bankCountry === "JP" && values?.bankCodeType.length > 0 && values?.bankCodeType === "LOCAL") ? (
                                                                            <Col md={6} xl={4}>
                                                                                <FormGroup className="mb-3">
                                                                                    <Label htmlFor="accountRoutingValue">
                                                                                        {t("ACCOUNT_ROUTING_VALUE")}
                                                                                        <sup
                                                                                            className="text-danger"> *</sup>
                                                                                    </Label>
                                                                                    <FormTextField
                                                                                        name="accountRoutingValue"
                                                                                        id="accountRoutingValue"
                                                                                        placeholder={t("ACCOUNT_ROUTING_VALUE")}

                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        ) : null

                                                                    ):
                                                                    null
                                                            }


                                                            {/* --------------------  Canada ---------------------------------------------- */}

                                                            {
                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string') ? (
                                                                        (values?.bankCountry === "CA" && values?.bankCodeType.length > 0 && values?.bankCodeType === "LOCAL") ? (
                                                                            <Col md={6} xl={4}>
                                                                                <FormGroup className="mb-3">
                                                                                    <Label htmlFor="accountRoutingValue">
                                                                                        {t("ACCOUNT_ROUTING_VALUE")}
                                                                                        <sup
                                                                                            className="text-danger"> *</sup>
                                                                                    </Label>
                                                                                    <FormTextField
                                                                                        name="accountRoutingValue"
                                                                                        id="accountRoutingValue"
                                                                                        placeholder={t("ACCOUNT_ROUTING_VALUE")}

                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        ) : null
                                                                    ):
                                                                    null
                                                            }

                                                            {/* --------------------  India ---------------------------------------------- */}

                                                            {
                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string') ? (
                                                                        (values?.bankCountry === "IN" && values?.bankCodeType.length > 0 && values?.bankCodeType === "LOCAL") ? (
                                                                            <Col md={6} xl={4}>
                                                                                <FormGroup className="mb-3">
                                                                                    <Label htmlFor="accountRoutingValue">
                                                                                        {t("ACCOUNT_ROUTING_VALUE")}
                                                                                        <sup
                                                                                            className="text-danger"> *</sup>
                                                                                    </Label>
                                                                                    <FormTextField
                                                                                        name="accountRoutingValue"
                                                                                        id="accountRoutingValue"
                                                                                        placeholder={t("ACCOUNT_ROUTING_VALUE")}

                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        ) : null

                                                                    ):
                                                                    null
                                                            }



                                                            <Col md={6} xl={4}>
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="accountCurrency">{t("ACCOUNT_CURRENCY")}<sup
                                                                        className="text-danger"> *</sup></Label>
                                                                    <FormDropdown name="accountCurrency"
                                                                                  id="accountCurrency"
                                                                                  placeholder={t("ACCOUNT_CURRENCY")}
                                                                                  optionsValues={isBankCurrency}
                                                                    />
                                                                </FormGroup>
                                                            </Col>

                                                            <Col md={6} xl={4}>
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="bankName">
                                                                        {t("BANK_NAME")}
                                                                        <sup className="text-danger"> *</sup>
                                                                    </Label>
                                                                    <FormTextField
                                                                        name="bankName"
                                                                        id="bankName"
                                                                        placeholder={t("BANK_NAME")}
                                                                    />
                                                                </FormGroup>
                                                            </Col>

                                                            <Col md={6} xl={4}>
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="bankAccountNumber">
                                                                        {t("BANK_ACCOUNT_NUMBER")}
                                                                        <sup className="text-danger"> *</sup>
                                                                    </Label>
                                                                    <FormTextField
                                                                        type="text"
                                                                        name="bankAccountNumber"
                                                                        id="bankAccountNumber"
                                                                        placeholder={t("BANK_ACCOUNT_NUMBER")}
                                                                    />
                                                                </FormGroup>
                                                            </Col>

                                                            <Col md={6} xl={4}>
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="bankAccountName">
                                                                        {t("BANK_ACCOUNT_NAME")}
                                                                        <sup className="text-danger"> *</sup>
                                                                    </Label>
                                                                    <FormTextField
                                                                        name="bankAccountName"
                                                                        id="bankAccountName"
                                                                        placeholder={t("BANK_ACCOUNT_NAME")}
                                                                    />
                                                                </FormGroup>
                                                            </Col>

                                                        </Row>
                                                    </CardBody>
                                                </Card>

                                                <Card
                                                >
                                                    <CardTitle tag="h5">
                                                        {t("PERSONAL_DETAILS")}
                                                    </CardTitle>
                                                    <CardBody>
                                                        <Row>

                                                            <Col md={6} xl={4}>
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="firstName">
                                                                        {t("FIRST_NAME")}
                                                                        <sup className="text-danger"> *</sup>
                                                                    </Label>
                                                                    <FormTextField
                                                                        name="firstName"
                                                                        id="firstName"
                                                                        placeholder={t("FIRST_NAME")}
                                                                    />
                                                                </FormGroup>
                                                            </Col>

                                                            <Col md={6} xl={4}>
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="lastName">
                                                                        {t("LAST_NAME")}
                                                                        <sup className="text-danger"> *</sup>
                                                                    </Label>
                                                                    <FormTextField
                                                                        name="lastName"
                                                                        id="lastName"
                                                                        placeholder={t("LAST_NAME")}
                                                                    />
                                                                </FormGroup>
                                                            </Col>


                                                            <Col md={6} xl={4}>
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="nickName">
                                                                        {t("NICK_NAME")}
                                                                        <sup className="text-danger"> *</sup>
                                                                    </Label>
                                                                    <FormTextField
                                                                        name="nickName"
                                                                        id="nickName"
                                                                        placeholder={t("NICK_NAME")}
                                                                    />
                                                                </FormGroup>
                                                            </Col>

                                                            <Col md={6} xl={4}>
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="email">
                                                                        {t("EMAIL")}
                                                                        <sup className="text-danger"> *</sup>
                                                                    </Label>
                                                                    <FormTextField
                                                                        name="email"
                                                                        id="email"
                                                                        placeholder={t("EMAIL")}
                                                                    />
                                                                </FormGroup>
                                                            </Col>

                                                            <Col md={6} xl={4}>
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="address">
                                                                        {t("ADDRESS")}
                                                                        <sup className="text-danger"> *</sup>
                                                                    </Label>
                                                                    <FormTextField
                                                                        name="address"
                                                                        id="address"
                                                                        placeholder={t("ADDRESS")}
                                                                    />
                                                                </FormGroup>
                                                            </Col>

                                                            <Col md={6} xl={4}>
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="city">
                                                                        {t("CITY")}
                                                                        <sup className="text-danger"> *</sup>
                                                                    </Label>
                                                                    <FormTextField
                                                                        name="city"
                                                                        id="city"
                                                                        placeholder={t("CITY")}
                                                                    />
                                                                </FormGroup>
                                                            </Col>

                                                            <Col md={6} xl={4}>
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="state">
                                                                        {t("STATE")}
                                                                        <sup className="text-danger"> *</sup>
                                                                    </Label>
                                                                    <FormTextField
                                                                        name="state"
                                                                        id="state"
                                                                        placeholder={t("STATE")}
                                                                    />
                                                                </FormGroup>
                                                            </Col>

                                                            <Col md={6} xl={4}>
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="postalCode">
                                                                        {t("POSTAL_CODE")}
                                                                        <sup className="text-danger"> *</sup>
                                                                    </Label>
                                                                    <FormTextField
                                                                        name="postalCode"
                                                                        id="postalCode"
                                                                        placeholder={t("POSTAL_CODE")}
                                                                    />
                                                                </FormGroup>
                                                            </Col>

                                                            <Col md={12} xl={12} className="d-flex justify-content-end align-items-center gap-3 mt-3">
                                                                <Button className="btn-secondary w-md" onClick={toggleModal}>{t("CLOSE")}</Button>
                                                                <Button className="btn-primary w-md" type="submit">
                                                                    { loading ? (
                                                                        <Spinner size="sm">
                                                                            Loading...
                                                                        </Spinner>
                                                                    ) : "" }
                                                                    {' '}{' '}{t("UPDATE")}
                                                                </Button>
                                                            </Col>

                                                        </Row>
                                                    </CardBody>
                                                </Card>

                                        </Form>
                                    )}
                                </Formik>
                            ) :

                            (
                                <Formik
                                    enableReinitialize
                                    initialValues={{
                                        bankCountry:rowData ? rowData.bank_country : "",
                                        accountCurrency:rowData ? rowData.bank_currency : "",
                                        bankCodeType:rowData?.payment_methods[0] === "SWIFT" ? "SWIFT" : (rowData?.payment_methods[0] === "LOCAL" && rowData.local_clearing_system < 0 ) ? "LOCAL" : rowData.local_clearing_system ? `${rowData?.payment_methods[0]} - ${rowData?.local_clearing_system}` : "",
                                        swiftCode:rowData?.swift_code ? rowData?.swift_code : "",
                                        bankName:rowData ? rowData.bank_name : "",
                                        bankAccountNumber:rowData ? rowData.accountNumber : "",
                                        bankAccountName:rowData ? rowData.account_name : "",
                                        businessName:rowData ? rowData.company_name : "",
                                        nickName:rowData ? rowData.nickname : "",
                                        email:rowData ? rowData.email : "",
                                        // country:"",
                                        address:rowData ? rowData.addressList[0].address_line1 : "",
                                        city:rowData ? rowData.addressList[0].city_province : "",
                                        state:rowData ? rowData.addressList[0].state : "",
                                        postalCode:rowData ? rowData.addressList[0].postal_code : "",
                                        legalRepFName:rowData ? rowData.legal_rep_first_name : "",
                                        legalRepLName:rowData ? rowData.legal_rep_last_name : "",
                                        localSwiftCode:(rowData?.payment_methods[0] === "LOCAL" && (rowData.bank_country === "SG" || (rowData.bank_country === "HK" && rowData.local_clearing_system === "RTGS")) ) ? rowData.swift_code : "",
                                        accountRoutingValue:rowData.account_routing_value1 ? rowData.account_routing_value1 : "",
                                    }}
                                    validationSchema={validationSchemaRegisterBusinessPayee}
                                    onSubmit={onSubmitRegisterBusinessPayee}
                                >
                                    {({ isSubmitting,values,setFieldValue  }) => (
                                        <Form>
                                                <Card

                                                >
                                                    <CardTitle tag="h5">
                                                        {t("BANK_ACCOUNT_DETAILS")}
                                                    </CardTitle>
                                                    <CardBody>
                                                        <Row>
                                                            <Col md={6} xl={4}>
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="status">{t("PAYEE_TYPE")}</Label>
                                                                    <br/>
                                                                    {<h4><Badge className={"py-1 bg-success"}>{rowData.payeeType}</Badge></h4>}
                                                                </FormGroup>
                                                            </Col>

                                                            <Col md={6} xl={4}>
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="bankCountry">
                                                                        {t("BANK_COUNTRY")}
                                                                        <sup className="text-danger"> *</sup>
                                                                    </Label>
                                                                    <FormDropdown
                                                                        name="bankCountry"
                                                                        id="bankCountry"
                                                                        optionsValues={countries}
                                                                        changeDropdown={changeCountry}
                                                                        setFieldValue={setFieldValue}
                                                                    />
                                                                </FormGroup>
                                                            </Col>

                                                            <Col md={6} xl={4}>
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="bankCodeType">
                                                                        {t("TRANSFER_METHOD")}
                                                                        <sup className="text-danger"> *</sup>
                                                                    </Label>
                                                                    <FormDropdown
                                                                        name="bankCodeType"
                                                                        id="bankCodeType"
                                                                        optionsValues={transferMethods}
                                                                    />
                                                                </FormGroup>
                                                            </Col>

                                                            {
                                                                values.bankCodeType === "SWIFT" ? (
                                                                        <Col md={6} xl={4}>
                                                                            <FormGroup className="mb-3">
                                                                                <Label htmlFor="swiftCode">
                                                                                    {t("SWIFT_CODE")}
                                                                                    <sup className="text-danger"> *</sup>
                                                                                </Label>
                                                                                <FormTextField
                                                                                    name="swiftCode"
                                                                                    id="swiftCode"
                                                                                    placeholder={t("SWIFT_CODE")}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>

                                                                    ) : null
                                                            }

                                                            {/* -------------------- Singapore ---------------------------------------------- */}

                                                            {
                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (
                                                                    (values?.bankCountry === "SG" && values?.bankCodeType?.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "LOCAL" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "FAST") ?(
                                                                        <Col md={6} xl={4}>
                                                                            <FormGroup className="mb-3">
                                                                                <Label htmlFor="localSwiftCode">
                                                                                    {t("SWIFT_CODE")}
                                                                                    <sup
                                                                                        className="text-danger"> *</sup>
                                                                                </Label>
                                                                                <FormTextField
                                                                                    name="localSwiftCode"
                                                                                    id="localSwiftCode"
                                                                                    placeholder={t("SWIFT_CODE")}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    ) : null
                                                                ): null
                                                            }

                                                            {
                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (
                                                                        (values?.bankCountry === "SG" && values?.bankCodeType?.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "LOCAL" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "GIRO") ? (
                                                                            <Col md={6} xl={4}>
                                                                                <FormGroup className="mb-3">
                                                                                    <Label htmlFor="localSwiftCode">
                                                                                        {t("SWIFT_CODE")}
                                                                                        <sup
                                                                                            className="text-danger"> *</sup>
                                                                                    </Label>
                                                                                    <FormTextField
                                                                                        name="localSwiftCode"
                                                                                        id="localSwiftCode"
                                                                                        placeholder={t("SWIFT_CODE")}

                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        ) : null
                                                                    ):
                                                                    null
                                                            }

                                                            {
                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (
                                                                        (values?.bankCountry === "SG" && values?.bankCodeType?.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "LOCAL" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "RTGS") ? (
                                                                            <Col md={6} xl={4}>
                                                                                <FormGroup className="mb-3">
                                                                                    <Label htmlFor="localSwiftCode">
                                                                                        {t("SWIFT_CODE")}
                                                                                        <sup
                                                                                            className="text-danger"> *</sup>
                                                                                    </Label>
                                                                                    <FormTextField
                                                                                        name="localSwiftCode"
                                                                                        id="localSwiftCode"
                                                                                        placeholder={t("SWIFT_CODE")}

                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        ) : null

                                                                    ):
                                                                    null
                                                            }

                                                            {/* --------------------  Hong Kong ---------------------------------------------- */}

                                                            {
                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (
                                                                        (values?.bankCountry === "HK" && values?.bankCodeType.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "LOCAL" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "ACH") ? (
                                                                            <Col md={6} xl={4}>
                                                                                <FormGroup className="mb-3">
                                                                                    <Label htmlFor="accountRoutingValue">
                                                                                        {t("ACCOUNT_ROUTING_VALUE")}
                                                                                        <sup
                                                                                            className="text-danger"> *</sup>
                                                                                    </Label>
                                                                                    <FormTextField
                                                                                        name="accountRoutingValue"
                                                                                        id="accountRoutingValue"
                                                                                        placeholder={t("ACCOUNT_ROUTING_VALUE")}

                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        ) : null

                                                                    ):
                                                                    null
                                                            }

                                                            {
                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (
                                                                        (values?.bankCountry === "HK" && values?.bankCodeType?.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "LOCAL" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "FPS") ? (
                                                                            <Col md={6} xl={4}>
                                                                                <FormGroup className="mb-3">
                                                                                    <Label htmlFor="accountRoutingValue">
                                                                                        {t("ACCOUNT_ROUTING_VALUE")}
                                                                                        <sup
                                                                                            className="text-danger"> *</sup>
                                                                                    </Label>
                                                                                    <FormTextField
                                                                                        name="accountRoutingValue"
                                                                                        id="accountRoutingValue"
                                                                                        placeholder={t("ACCOUNT_ROUTING_VALUE")}

                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        ) : null

                                                                    ):
                                                                    null
                                                            }

                                                            {
                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (
                                                                        (values?.bankCountry === "HK" && values?.bankCodeType?.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "LOCAL" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "RTGS") ? (
                                                                            <Col md={6} xl={4}>
                                                                                <FormGroup className="mb-3">
                                                                                    <Label htmlFor="localSwiftCode">
                                                                                        {t("SWIFT_CODE")}
                                                                                        <sup
                                                                                            className="text-danger"> *</sup>
                                                                                    </Label>
                                                                                    <FormTextField
                                                                                        name="localSwiftCode"
                                                                                        id="localSwiftCode"
                                                                                        placeholder={t("SWIFT_CODE")}

                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        ) : null

                                                                    ):
                                                                    null
                                                            }

                                                            {/* --------------------  China ---------------------------------------------- */}

                                                            {
                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string') ? (
                                                                        (values?.bankCountry === "CN" && values?.bankCodeType.length > 0 && values?.bankCodeType === "LOCAL") ?(
                                                                            <Col md={6} xl={4}>
                                                                                <FormGroup className="mb-3">
                                                                                    <Label htmlFor="accountRoutingValue">
                                                                                        {t("ACCOUNT_ROUTING_VALUE")}
                                                                                        <sup
                                                                                            className="text-danger"> *</sup>
                                                                                    </Label>
                                                                                    <FormTextField
                                                                                        name="accountRoutingValue"
                                                                                        id="accountRoutingValue"
                                                                                        placeholder={t("ACCOUNT_ROUTING_VALUE")}

                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        ) : null

                                                                    ):
                                                                    null
                                                            }

                                                            {/* --------------------  United Kingdom ---------------------------------------------- */}

                                                            {
                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (
                                                                        (values?.bankCountry === "GB" && values?.bankCodeType.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "LOCAL" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "FASTER_PAYMENTS") ? (
                                                                            <Col md={6} xl={4}>
                                                                                <FormGroup className="mb-3">
                                                                                    <Label htmlFor="accountRoutingValue">
                                                                                        {t("ACCOUNT_ROUTING_VALUE")}
                                                                                        <sup
                                                                                            className="text-danger"> *</sup>
                                                                                    </Label>
                                                                                    <FormTextField
                                                                                        name="accountRoutingValue"
                                                                                        id="accountRoutingValue"
                                                                                        placeholder={t("ACCOUNT_ROUTING_VALUE")}

                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        ) : null

                                                                    ):
                                                                    null
                                                            }

                                                            {/* --------------------  United State ---------------------------------------------- */}

                                                            {
                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (
                                                                        (values?.bankCountry === "US" && values?.bankCodeType?.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "LOCAL" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "ACH") ? (
                                                                            <Col md={6} xl={4}>
                                                                                <FormGroup className="mb-3">
                                                                                    <Label htmlFor="accountRoutingValue">
                                                                                        {t("ACCOUNT_ROUTING_VALUE")}
                                                                                        <sup
                                                                                            className="text-danger"> *</sup>
                                                                                    </Label>
                                                                                    <FormTextField
                                                                                        name="accountRoutingValue"
                                                                                        id="accountRoutingValue"
                                                                                        placeholder={t("ACCOUNT_ROUTING_VALUE")}

                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        ) : null

                                                                    ):
                                                                    null
                                                            }

                                                            {
                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (
                                                                        (values?.bankCountry === "US" && values?.bankCodeType?.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "LOCAL" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "FEDWIRE") ? (
                                                                            <Col md={6} xl={4}>
                                                                                <FormGroup className="mb-3">
                                                                                    <Label htmlFor="accountRoutingValue">
                                                                                        {t("ACCOUNT_ROUTING_VALUE")}
                                                                                        <sup
                                                                                            className="text-danger"> *</sup>
                                                                                    </Label>
                                                                                    <FormTextField
                                                                                        name="accountRoutingValue"
                                                                                        id="accountRoutingValue"
                                                                                        placeholder={t("ACCOUNT_ROUTING_VALUE")}

                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        ) : null

                                                                    ):
                                                                    null
                                                            }

                                                            {/*{*/}
                                                            {/*    (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (*/}
                                                            {/*        (values?.bankCountry === "US" && values?.bankCodeType?.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "local" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "paper_check") ? (*/}
                                                            {/*            <Col md={6} xl={6}>*/}
                                                            {/*                <FormGroup className="mb-3">*/}
                                                            {/*                    <Label htmlFor="accountRoutingValue">*/}
                                                            {/*                        {t("ACCOUNT_ROUTING_VALUE")}*/}
                                                            {/*                        <sup*/}
                                                            {/*                            className="text-danger"> *</sup>*/}
                                                            {/*                    </Label>*/}
                                                            {/*                    <FormTextField*/}
                                                            {/*                        name="accountRoutingValue"*/}
                                                            {/*                        id="accountRoutingValue"*/}
                                                            {/*                        placeholder={t("ACCOUNT_ROUTING_VALUE")}*/}
                                                            {/*                        disabled={isAccountActive ? false : true}*/}
                                                            {/*                    />*/}
                                                            {/*                </FormGroup>*/}
                                                            {/*            </Col>*/}
                                                            {/*            ) : null*/}

                                                            {/*        ):*/}
                                                            {/*        null*/}
                                                            {/*}*/}

                                                            {/* --------------------  Australia ---------------------------------------------- */}

                                                            {
                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (
                                                                        (values?.bankCountry === "AU" && values?.bankCodeType?.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "LOCAL" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "BPAY") ? (
                                                                            <Col md={6} xl={4}>
                                                                                <FormGroup className="mb-3">
                                                                                    <Label htmlFor="accountRoutingValue">
                                                                                        {t("ACCOUNT_ROUTING_VALUE")}
                                                                                        <sup
                                                                                            className="text-danger"> *</sup>
                                                                                    </Label>
                                                                                    <FormTextField
                                                                                        name="accountRoutingValue"
                                                                                        id="accountRoutingValue"
                                                                                        placeholder={t("ACCOUNT_ROUTING_VALUE")}

                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        ) : null

                                                                    ):
                                                                    null
                                                            }

                                                            {
                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string' && values?.bankCodeType.includes("-")) ? (
                                                                        (values?.bankCountry === "AU" && values.bankCodeType.split("-").length > 0 && values?.bankCodeType?.split("-")[0].replace(/\s/g, '') === "LOCAL" && values?.bankCodeType?.split("-")[1].replace(/\s/g, '') === "BANK_TRANSFER") ? (
                                                                            <Col md={6} xl={4}>
                                                                                <FormGroup className="mb-3">
                                                                                    <Label htmlFor="accountRoutingValue">
                                                                                        {t("ACCOUNT_ROUTING_VALUE")}
                                                                                        <sup
                                                                                            className="text-danger"> *</sup>
                                                                                    </Label>
                                                                                    <FormTextField
                                                                                        name="accountRoutingValue"
                                                                                        id="accountRoutingValue"
                                                                                        placeholder={t("ACCOUNT_ROUTING_VALUE")}

                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        ) : null

                                                                    ):
                                                                    null
                                                            }

                                                            {/* --------------------  Japan ---------------------------------------------- */}

                                                            {
                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string') ? (
                                                                        (values?.bankCountry === "JP" && values?.bankCodeType.length > 0 && values?.bankCodeType === "LOCAL") ? (
                                                                            <Col md={6} xl={4}>
                                                                                <FormGroup className="mb-3">
                                                                                    <Label htmlFor="accountRoutingValue">
                                                                                        {t("ACCOUNT_ROUTING_VALUE")}
                                                                                        <sup
                                                                                            className="text-danger"> *</sup>
                                                                                    </Label>
                                                                                    <FormTextField
                                                                                        name="accountRoutingValue"
                                                                                        id="accountRoutingValue"
                                                                                        placeholder={t("ACCOUNT_ROUTING_VALUE")}

                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        ) : null

                                                                    ):
                                                                    null
                                                            }


                                                            {/* --------------------  Canada ---------------------------------------------- */}

                                                            {
                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string') ? (
                                                                        (values?.bankCountry === "CA" && values?.bankCodeType.length > 0 && values?.bankCodeType === "LOCAL") ? (
                                                                            <Col md={6} xl={4}>
                                                                                <FormGroup className="mb-3">
                                                                                    <Label htmlFor="accountRoutingValue">
                                                                                        {t("ACCOUNT_ROUTING_VALUE")}
                                                                                        <sup
                                                                                            className="text-danger"> *</sup>
                                                                                    </Label>
                                                                                    <FormTextField
                                                                                        name="accountRoutingValue"
                                                                                        id="accountRoutingValue"
                                                                                        placeholder={t("ACCOUNT_ROUTING_VALUE")}

                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        ) : null
                                                                    ):
                                                                    null
                                                            }

                                                            {/* --------------------  India ---------------------------------------------- */}

                                                            {
                                                                (values?.bankCountry && values?.bankCodeType && typeof values?.bankCodeType === 'string') ? (
                                                                        (values?.bankCountry === "IN" && values?.bankCodeType.length > 0 && values?.bankCodeType === "LOCAL") ? (
                                                                            <Col md={6} xl={4}>
                                                                                <FormGroup className="mb-3">
                                                                                    <Label htmlFor="accountRoutingValue">
                                                                                        {t("ACCOUNT_ROUTING_VALUE")}
                                                                                        <sup
                                                                                            className="text-danger"> *</sup>
                                                                                    </Label>
                                                                                    <FormTextField
                                                                                        name="accountRoutingValue"
                                                                                        id="accountRoutingValue"
                                                                                        placeholder={t("ACCOUNT_ROUTING_VALUE")}

                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        ) : null

                                                                    ):
                                                                    null
                                                            }



                                                            <Col md={6} xl={4}>
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="accountCurrency">{t("ACCOUNT_CURRENCY")}<sup
                                                                        className="text-danger"> *</sup></Label>
                                                                    <FormDropdown name="accountCurrency"
                                                                                  id="accountCurrency"
                                                                                  placeholder={t("ACCOUNT_CURRENCY")}
                                                                                  optionsValues={isBankCurrency}
                                                                    />
                                                                </FormGroup>
                                                            </Col>

                                                            <Col md={6} xl={4}>
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="bankName">
                                                                        {t("BANK_NAME")}
                                                                        <sup className="text-danger"> *</sup>
                                                                    </Label>
                                                                    <FormTextField
                                                                        name="bankName"
                                                                        id="bankName"
                                                                        placeholder={t("BANK_NAME")}
                                                                    />
                                                                </FormGroup>
                                                            </Col>

                                                            <Col md={6} xl={4}>
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="bankAccountNumber">
                                                                        {t("BANK_ACCOUNT_NUMBER")}
                                                                        <sup className="text-danger"> *</sup>
                                                                    </Label>
                                                                    <FormTextField
                                                                        type="text"
                                                                        name="bankAccountNumber"
                                                                        id="bankAccountNumber"
                                                                        placeholder={t("BANK_ACCOUNT_NUMBER")}
                                                                    />
                                                                </FormGroup>
                                                            </Col>

                                                            <Col md={6} xl={4}>
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="bankAccountName">
                                                                        {t("BANK_ACCOUNT_NAME")}
                                                                        <sup className="text-danger"> *</sup>
                                                                    </Label>
                                                                    <FormTextField
                                                                        name="bankAccountName"
                                                                        id="bankAccountName"
                                                                        placeholder={t("BANK_ACCOUNT_NAME")}
                                                                    />
                                                                </FormGroup>
                                                            </Col>

                                                        </Row>
                                                    </CardBody>
                                                </Card>

                                                <Card
                                                >
                                                    <CardTitle tag="h5">
                                                        {t("BUSINESS_DETAILS")}
                                                    </CardTitle>
                                                    <CardBody>
                                                        <Row>

                                                            <Col md={6} xl={4}>
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="businessName">
                                                                        {t("BUSINESS_NAME")}
                                                                        <sup className="text-danger"> *</sup>
                                                                    </Label>
                                                                    <FormTextField
                                                                        name="businessName"
                                                                        id="businessName"
                                                                        placeholder={t("BUSINESS_NAME")}
                                                                    />
                                                                </FormGroup>
                                                            </Col>

                                                            <Col md={6} xl={4}>
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="nickName">
                                                                        {t("NICK_NAME")}
                                                                        <sup className="text-danger"> *</sup>
                                                                    </Label>
                                                                    <FormTextField
                                                                        name="nickName"
                                                                        id="nickName"
                                                                        placeholder={t("NICK_NAME")}
                                                                    />
                                                                </FormGroup>
                                                            </Col>

                                                            <Col md={6} xl={4}>
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="email">
                                                                        {t("EMAIL")}
                                                                        <sup className="text-danger"> *</sup>
                                                                    </Label>
                                                                    <FormTextField
                                                                        name="email"
                                                                        id="email"
                                                                        placeholder={t("EMAIL")}
                                                                    />
                                                                </FormGroup>
                                                            </Col>


                                                            <Col md={6} xl={4}>
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="address">
                                                                        {t("ADDRESS")}
                                                                        <sup className="text-danger"> *</sup>
                                                                    </Label>
                                                                    <FormTextField
                                                                        name="address"
                                                                        id="address"
                                                                        placeholder={t("ADDRESS")}
                                                                    />
                                                                </FormGroup>
                                                            </Col>

                                                            <Col md={6} xl={4}>
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="city">
                                                                        {t("CITY")}
                                                                        <sup className="text-danger"> *</sup>
                                                                    </Label>
                                                                    <FormTextField
                                                                        name="city"
                                                                        id="city"
                                                                        placeholder={t("CITY")}
                                                                    />
                                                                </FormGroup>
                                                            </Col>

                                                            <Col md={6} xl={4}>
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="state">
                                                                        {t("STATE")}
                                                                        <sup className="text-danger"> *</sup>
                                                                    </Label>
                                                                    <FormTextField
                                                                        name="state"
                                                                        id="state"
                                                                        placeholder={t("STATE")}
                                                                    />
                                                                </FormGroup>
                                                            </Col>

                                                            <Col md={6} xl={4}>
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="postalCode">
                                                                        {t("POSTAL_CODE")}
                                                                        <sup className="text-danger"> *</sup>
                                                                    </Label>
                                                                    <FormTextField
                                                                        name="postalCode"
                                                                        id="postalCode"
                                                                        placeholder={t("POSTAL_CODE")}
                                                                    />
                                                                </FormGroup>
                                                            </Col>

                                                            {
                                                                values.bankCountry === "CN" ? (
                                                                        <>
                                                                            <Col md={6} xl={4}>
                                                                                <FormGroup className="mb-3">
                                                                                    <Label htmlFor="legalRepFName">
                                                                                        {t("LEGAL_REPRESENTATIVE_FIRST_NAME_IN_CHINESE_LANGUAGE")}
                                                                                        <sup className="text-danger"> *</sup>
                                                                                    </Label>
                                                                                    <FormTextField
                                                                                        name="legalRepFName"
                                                                                        id="legalRepFName"
                                                                                        placeholder={t("LEGAL_REPRESENTATIVE_FIRST_NAME_IN_CHINESE_LANGUAGE")}
                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>

                                                                            <Col md={6} xl={4}>
                                                                                <FormGroup className="mb-3">
                                                                                    <Label htmlFor="legalRepLName">
                                                                                        {t("LEGAL_REPRESENTATIVE_LAST_NAME_IN_CHINESE_LANGUAGE")}
                                                                                        <sup className="text-danger"> *</sup>
                                                                                    </Label>
                                                                                    <FormTextField
                                                                                        name="legalRepLName"
                                                                                        id="legalRepLName"
                                                                                        placeholder={t("LEGAL_REPRESENTATIVE_LAST_NAME_IN_CHINESE_LANGUAGE")}
                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        </>
                                                                    ) :
                                                                    ( " " )
                                                            }

                                                            <Col md={12} xl={12} className="d-flex justify-content-end align-items-center gap-3 mt-3">
                                                                <Button className="btn-secondary w-md" onClick={toggleModal}>{t("CLOSE")}</Button>
                                                                <Button className="btn-primary w-md" type="submit">
                                                                    { loading ? (
                                                                        <Spinner size="sm">
                                                                            Loading...
                                                                        </Spinner>
                                                                    ) : "" }
                                                                    {' '}{' '}{t("UPDATE")}</Button>
                                                            </Col>

                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                        </Form>
                                    )}
                                </Formik>
                            )
                    }


                </ModalBody>
            </Modal>
        </div>
    )


}

export default RegisterPayeeHistory;